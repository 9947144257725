import React from "react";
import {SvgIcon} from "@mui/material";
import {ReactComponent as HeadLogo } from "../../../assets/images/svg/svgLogo/logo-h-inverse2.svg";
import Box from "@mui/material/Box";

class Header extends React.Component {
    render() {
        return(

            <header className='header-landing'>
                <Box className='navigation'>
                    <div className='logo-container'>
                        <SvgIcon className='logo' component={HeadLogo} inheritViewBox />
                    </div>
                </Box>
            </header>
        );
    }
}
export default Header;
