import React, {useEffect, useState} from 'react';
import {getProfile, currentUserInterface} from "../libs/Auth";
import {CurrentUserProvider} from "../context/CurrentUserContext";
interface propsInterface {
    component: React.JSX.Element;
    trytogetuser?: boolean;
}

const UnAuthGuard = ({component,trytogetuser}: propsInterface) => {
    const [status, setStatus] = useState(false);
    const [currentUser, setCurrentUser] = useState({} as currentUserInterface);
    useEffect(() => {
        if(trytogetuser){
            checkToken();
        }else{
            setStatus(true);
        }
    }, []);

    const checkToken = async () => {
        const user = await getProfile();
        if(user){
            setCurrentUser(user as currentUserInterface);
        }
        setStatus(true);
    }

    //return <React.Fragment>{component}</React.Fragment>
    return status ? <React.Fragment><CurrentUserProvider value={currentUser}>{component}</CurrentUserProvider></React.Fragment> : <React.Fragment/>
}

export default UnAuthGuard;
