import TextField from "@mui/material/TextField";
import {TextFieldProps} from "@mui/material/TextField";
import React from "react";

export default function LCInputText(props:TextFieldProps) {
    return (
        <TextField
            {...props}
            sx={{
                "& div.MuiInputBase-root": {
                    borderRadius: '30px',
                    border: 0,
                    // boxShadow: 'inset 0 0 7px #999',
                },
                "& label.MuiFormLabel-root.Mui-focused, label.MuiInputLabel-outlined": {
                    display: 'none',
                },
                "& fieldset legend": {
                  display:'none',
                },
                "& fieldset": {
                    border:'none',
                },

                ...props.sx
            }}
        />
    )
}
