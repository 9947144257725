import * as React from "react";
import Box from "@mui/material/Box";
import Women from "../../assets/images/femme-telephone.png";
import { motion } from "framer-motion";

export default function BlockOneThird() {
    return (
        <Box className={'block-one-third'}>
            <motion.div className={'block-one-third--left'}
                        initial={{ opacity: 0, transform: 'translateY(100px)' }}
                        whileInView={{ opacity: 1, transform: 'translateY(0px)' }}
                        transition={{ ease: "easeOut", duration: 0.5}}
                        viewport={{ once: true }}
            >
                <div className={'content'}>
                    <h2>Développé pour les municipalités et les citoyens</h2>
                    <p>Conçue en partenariat étroit avec les municipalités, notre application offre aux citoyens un accès rapide à l'information et une interface de communication directe avec leur administration municipale.</p>
                    <button className={'btn btn-yellow btn-shadow'} onClick={() => window.location.href = 'mailto:liencitoyen@cognitif.ca' }>Nous contacter</button>
                </div>
            </motion.div>

            <motion.div className={'block-one-third--right'}
                        initial={{ opacity: 0, transform: 'translateX(100%)' }}
                        whileInView={{ opacity: 1, transform: 'translateX(0)' }}
                        transition={{ ease: "easeOut", duration: 1.5}}
                        viewport={{ once: true }}
            >
                <img className='block-image' src={ Women } alt={''}/>
            </motion.div>
        </Box>
    )
}
