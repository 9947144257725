import React, {useEffect, useState} from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogContentText,
    DialogActions, Button
} from "@mui/material";
import LCInputText from "./LCInputText";
import Apicall from "../libs/Apicall";
import Alert from "@mui/material/Alert";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    content?: string; // Nouveau prop facultatif
}
export default function SendTestVoiceCallModal({ isOpen, onClose, content }: Props) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [localContent, setLocalContent] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState<null|string>(null);

    const handleContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalContent(e.target.value);
    };

    const handleSendClick = () => {
        //send msg
        const apicall = new Apicall();
        setError(null);
        setIsSuccess(false);
        apicall.call('POST', 'alerts/test-voice-call', {content: content, phone: phoneNumber}).then((response) => {
            if(response.status === 'OK'){
                setIsSuccess(true);
            }else{
                setError(response.error || 'Erreur serveur');
            }
        });
    };

    useEffect(() => {
        setIsSuccess(false);
        setError(null);
    }, [isOpen]);

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Entrer un numéro de téléphone</DialogTitle>
            {isSuccess &&
                <Alert severity="success">Le test message vocal a été envoyé...</Alert>
            }
            {error &&
                <Alert severity="error">{error}</Alert>
            }
            <DialogContent>
                <DialogContentText>
                    Veuillez entrer le numéro de téléphone pour envoyer un test message Vocal.
                </DialogContentText>
                <LCInputText
                    fullWidth
                    value={phoneNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
                    placeholder="Numéro de téléphone"
                />

                {!content && (
                    <>
                        <LCInputText
                            margin="normal"
                            required
                            fullWidth
                            placeholder={"Contenu du message SMS"}
                            value={localContent}
                            onChange={handleContentChange}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Fermer
                </Button>
                <Button onClick={handleSendClick} color="primary" disabled={phoneNumber.length !== 10}>
                    Envoyer Test
                </Button>
            </DialogActions>
        </Dialog>
    );
}
