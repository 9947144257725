import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

class Footer extends React.Component {
    render() {
        return(
            <footer>
                <Box className='footer-container' sx={{ display:'flex', flexWrap:'wrap', justifyContent:{xs:'center', sm:'space-between'} }}>
                    <Typography>©2023 - Tous droits réservés Lien Citoyen</Typography>
                    <Typography sx={{ }}><a href='/politique-confidentialite'>Politique de confidentialité</a></Typography>
                    <Typography sx={{ }}>Une application <b><a href='https://cognitif.ca/' target='_blank' rel="noreferrer">cognitif</a></b></Typography>
                </Box>
            </footer>
        );
    }
}
export default Footer;
