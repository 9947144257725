import React from "react";
import {SvgIcon} from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {ReactComponent as HeadLogo } from "../../../assets/images/svg/svgLogo/logo-complet.svg";
import Typography from "@mui/material/Typography";
import FooterImage from "../../../assets/images/dude-telephone.png";


class LandingFooter extends React.Component {
    render() {
        return(
            <footer className={'footer-landing'}>
                <Grid container className={'footer-content'} >

                   <Grid className={'left-side'} xs={12} sm={6} md={7}>
                       <Box> <SvgIcon className='logo' component={HeadLogo} inheritViewBox /> </Box>
                       <Box className={'contact-infos'}>
                           <Typography variant={'h4'}>Nous joindre</Typography>
                           <a href={'mailto:liencitoyen@cognitif.ca?subject=Intérêt pour connecter ma ville'}>info@liencitoyen.com</a>
                           <a href={'tel:(819)4988377'}>(819) 498-8377</a>
                       </Box>
                   </Grid>

                    <Grid className={'right-side'} xs={12} sm={6} md={5}>
                        <img className='footer-image' alt={'Lien citoyen - Inscrivez-vous'} src={ FooterImage } />
                    </Grid>

                </Grid>
            </footer>
        );
    }
}
export default LandingFooter;
