import React, {useState, ChangeEvent} from 'react';
import Alerttypes from '../../libs/Alerttypes';
import Grid from "@mui/material/Grid";
import LCContainer from "../_Base/LCContainer";
import Button from "@mui/material/Button";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import Apicall from "../../libs/Apicall";
import {currentMunicipalityInterface} from "../../App";
import {CitizenFormData} from "../Public/Citizenonboarding";

import Baseinfosform from "../Public/OnboardingFormParts/Baseinfosform";
import AddressForm from "../Public/OnboardingFormParts/AddressForm";
import Alertspreferences from "../Public/OnboardingFormParts/Alertspreferences";
import PasswordForm from "../Public/OnboardingFormParts/PasswordForm";
import Confirmlocationform from "../Public/OnboardingFormParts/Confirmlocationform";
import Alert from "@mui/material/Alert";
import {Container} from "@mui/material";

interface propsInterface {
    municipality: currentMunicipalityInterface;
}

export default function Newcitizen({municipality}: propsInterface) {

    const navigate = useNavigate();

    const fieldTranslations: Record<keyof CitizenFormData, string> = {
        id: '',
        city_id: "",
        firstname: "Prénom",
        lastname: "Nom",
        email: "Adresse courriel",
        phone_home: "Numéro de téléphone",
        phone_mobile: "Numéro de cellulaire",
        address: "Adresse",
        apartment: "Appartement",
        city: "Ville",
        postal_code: "Code postal",
        password: "Mot de passe",
        confirmPassword: "Confirmation du mot de passe",
        alert_sms: "",
        alert_email: "",
        alert_voice: "",
        latitude: "",
        longitude: "",
    };

    const [formData, setFormData] = useState<CitizenFormData>({
        city_id: municipality.id,
        firstname: "",
        lastname: "",
        phone_home: '',
        phone_mobile: '',
        email: "",
        address: "",
        apartment: "",
        city: municipality.default_city_name,
        postal_code: "",
        password: "",
        confirmPassword: "",
        alert_sms: false,
        alert_email: true,
        alert_voice: false,
        latitude: null,
        longitude: null,
    });
    const [alertError, setAlertError] = useState<string | null>(null);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        let formattedValue = value;
        if (name === "phone_home" || name === "phone_mobile") {
            formattedValue = formatPhoneNumber(value);
        }
        setFormData((prev) => (
                {...prev, [name]: formattedValue}
            )
        );
    };

    const formatPhoneNumber = (value: string) => {
        const onlyNums = value.replace(/[^\d]/g, '');
        if (onlyNums.length < 4) return onlyNums;
        if (onlyNums.length < 7) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
        return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
    };

    const [alerts, setAlerts] = useState<string[]>([]);

    const handleToggleChange = (type: string, value: string) => {
        if (alerts.includes(value)) {
            setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert !== value));
        } else {
            setAlerts((prevAlerts) => [...prevAlerts, value]);
        }
    };

    const selectAllAlerts = () => {
        setAlerts(Alerttypes.map(alert => alert.key));
    };

    const deselectAllAlerts = () => {
        setAlerts([]);
    };

    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Informations de base', 'Adresse', 'Préférences d\'alerte', 'Sécurité', 'Confirmez votre position'];
    const handleNext = () => {
        let errorMessage = "";
        switch(activeStep) {
            case 0:
                const requiredFields: Array<keyof CitizenFormData> = ["firstname", "lastname", "email"];
                for (let field of requiredFields) {
                    if (!formData[field]) {
                        errorMessage += `${fieldTranslations[field]} est obligatoire. `;
                    }
                }

                if (!formData.phone_home && !formData.phone_mobile) {
                    errorMessage += "Au moins un des champs Numéro de téléphone ou Numéro de cellulaire doit être renseigné. ";
                }

                if (errorMessage) {
                    setAlertError(errorMessage);
                } else {
                    setAlertError(null);
                    // Effectuer le call API...
                    // Etape 2: Appel API pour la validation
                    const apicall = new Apicall();
                    apicall.call('POST', 'register/validate-email-and-phone', {
                        email: formData.email,
                        phone_home: formData.phone_home,
                        phone_mobile: formData.phone_mobile,
                        city_id: formData.city_id
                    }).then((response) => {
                        // Etape 3: Gestion de la réponse de l'API
                        if (response.status === 'ERROR') {
                            setAlertError(String(response.error));
                        } else {
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        }
                    }).catch(() => {
                        setAlertError("Une erreur est survenue lors de la validation. Veuillez réessayer.");
                    });
                }
                break;
            case 1:
                // Etape 2: Vérification des champs obligatoires pour l'adresse
                const requiredAddressFields: Array<keyof CitizenFormData> = ["address", "city", "postal_code"];
                for (let field of requiredAddressFields) {
                    if (!formData[field]) {
                        errorMessage += `${fieldTranslations[field]} est obligatoire. `;
                    }
                }

                if (errorMessage) {
                    setAlertError(errorMessage);
                } else {
                    setAlertError(null);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                break;
            case 3:
                if (!formData.password) {
                    errorMessage = "Le champ mot de passe est obligatoire. ";
                } else if (formData.password !== formData.confirmPassword) {
                    errorMessage = "Le mot de passe et la confirmation du mot de passe ne correspondent pas. ";
                } else if (formData.password.length < 6) {
                    errorMessage = "Le mot de passe doit avoir au moins 6 caractères. ";
                }

                if (errorMessage) {
                    setAlertError(errorMessage);
                } else {
                    setAlertError(null);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                break;
            case 4:
                if (formData.latitude !== null && formData.longitude !== null) {
                    const apicall = new Apicall();
                    apicall.call('POST', 'register/new-citizen', {formData, alerts})
                        .then((response) => {
                            if (response.status === 'OK') {
                                setAlertError(null);
                                navigate('/citoyens')
                            } else {
                                setAlertError(String(response.error));
                            }
                        })
                        .catch(() => {
                            setAlertError("Une erreur est survenue lors de l'enregistrement. Veuillez réessayer.");
                        });
                } else {
                    setAlertError("Veuillez confirmer votre position.");
                }
                break;
            default:
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    return (
        <LCContainer classSection={'new-citizen'} title={'Ajouter un citoyen'}>
            <Container /*className={'wrapper-board'}*/>
                <Stepper className={'step-container'} activeStep={activeStep} sx={{width: '100%', pt:3, pb:3}}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {alertError && (
                    <Grid item xs={12}>
                        <Alert severity="error">{alertError}</Alert>
                    </Grid>
                )}
                <Box sx={{px: 3, width: '100%'}}>

                    {activeStep === 0 && (
                        <Baseinfosform formData={formData} handleInputChange={handleInputChange} />
                    )}

                    {activeStep === 1 && (
                        <AddressForm formData={formData} handleInputChange={handleInputChange} />
                    )}

                    {activeStep === 2 && (
                        <Alertspreferences
                            canUseVoiceMsg={municipality.can_use_voice_msg}
                            formData={formData}
                            alerts={alerts}
                            handleToggleChange={handleToggleChange}
                            setFormData={setFormData}
                            selectAllAlerts={selectAllAlerts}
                            deselectAllAlerts={deselectAllAlerts}
                        />
                    )}

                    {activeStep === 3 && (
                        <PasswordForm formData={formData} handleInputChange={handleInputChange} />
                    )}

                    {activeStep === 4 && (
                        <Confirmlocationform formData={formData} handleInputChange={handleInputChange} default_lat={municipality.defaultlat} default_lng={municipality.defaultlng} />
                    )}

                    <Box sx={{marginTop:'100px', display:'flex', justifyContent:'space-between', flexWrap:'wrap'}}>
                        {/*<Grid container spacing={2} sx={{pt:4}}>*/}
                        <Grid item>
                            <Button disabled={activeStep === 0} className={activeStep === 0 ? 'nv' : 'btn btn-small btn-icon btn-icon-arrowl btn-red btn-shadow' } onClick={handleBack}>Précédent</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained"  className={activeStep === steps.length - 1 ? 'btn btn-small btn-blue btn-shadow btn-icon btn-icon-checked' : 'btn btn-small btn-yellow btn-icon btn-icon-arrowr btn-cwhite btn-shadow'} color="primary" onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Confirmer l\'inscription' : 'Étape suivante'}
                            </Button>
                        </Grid>
                        {/*</Grid>*/}
                    </Box>
                </Box>
            </Container>
        </LCContainer>
    );
}
