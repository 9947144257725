import * as React from "react";
import Box from "@mui/material/Box";

type Props = {
    image:string;
}

export default function Heroband ({ image }: Props) {
    return (
        <Box className="hero-banner" >
            <img className="hero-banner__img" alt='Ville' src={ image } />
        </Box>
    );
}
