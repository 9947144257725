import React from 'react';
import Header from "../../components/Layout/Header/Header";
import HeroBlock from "../../components/Hero/HeroBlock";
import BlockOneThird from "./BlockOneThird";
import BlockCenter from "./BlockCenter";
import LandingFooter from "../../components/Layout/Footer/LandingFooter";
import Prefooter from "./Prefooter";
import BlockInfosLanding from "./BlockInfosLanding";
import Box from "@mui/material/Box";

export default function Home() {
    return (
        <React.Fragment>
            <Box sx={{overflow:'hidden'}}>
                <Header/>
                <HeroBlock />
                <BlockOneThird />
                <BlockCenter />
                <div className={'wrapper-prefooter'}>
                    <BlockInfosLanding/>
                    <Prefooter/>
                </div>
                <LandingFooter />
            </Box>
        </React.Fragment>
    )
}
