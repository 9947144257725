import React from "react";
import Paper from "@mui/material/Paper";
import {SxProps, Theme} from "@mui/material";

type Props = {
    children: React.ReactNode,
    sx: SxProps<Theme>
}
export default function LCPaper({children, sx}: Props) {
    return (
        <Paper className='blocks' elevation={0} sx={{...sx, p: 2, /*boxShadow: 'inset 0 0 7px #999', borderRadius: '10px', backgroundColor: '#EFF2F3'*/}}>
            {children}
        </Paper>
    )
}
