import React, {useContext, useEffect, useState} from 'react';
import {currentMunicipalityInterface} from "../../App";
import Sidebar from "../Sidebar/Sidebar";
import Alertnotes from "../../components/Alerts/Alertnotes"
import {Alert, Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import Heroband from "../../components/Hero/Hero";
import CurrentUserContext from "../../context/CurrentUserContext";
import Apicall from "../../libs/Apicall";

interface propsInterface {
    municipality: currentMunicipalityInterface
}

interface userInterface {
    id: number;
    created_at: string;
    firstname: string;
    lastname: string;
    email: string;
    role: string;
}

interface AlertInterface {
    id: number;
    title: string;
    end_date: Date;
    description: string;
    url: string;
    type: string;
}

export default function Homepage({municipality}: propsInterface) {

    const [error, setError] = useState('');
    const [alerts, setAlerts] = useState<AlertInterface[]>([]);
    const [alertIcons,setAlertIcons] = useState<Record<string, any>>({});

    const currentuser:userInterface = useContext(CurrentUserContext) as userInterface;

    useEffect(() => {
        const apicall = new Apicall();
        if(currentuser && currentuser.id) {
            apicall.call('POST', 'me/alerts', {city_id: municipality.id}).then((response) => {
                if(response.status === 'OK') {
                    setAlerts(response.data.alerts);
                    setAlertIcons(response.data.icons);
                }else{
                    setError(String(response.error));
                }
            });
        }else{
            apicall.call('POST','public/alerts-for-public-dashboard', {city_id: municipality.id}).then((response) => {
                if(response.status === 'OK') {
                    setAlerts(response.data.alerts);
                    setAlertIcons(response.data.icons);
                }else{
                    setError(String(response.error));
                }
            });
        }
    }, []);

    return (
        <Sidebar title={'Page '+municipality.default_city_name} currentuser={currentuser} municipality={municipality}>
                <Heroband image={'../images/'+municipality.splash_background}/>
                <Container className={'head-content'} sx={{ maxWidth:{xl:'1680px'} }}>
                    <Typography variant={'h3'} className='page-title' sx={{ color:'#FFB800', mb:'43px', mt:'34px' }}>Alertes en vigueur</Typography>
                </Container>

                <Container className={'alert-container'} sx={{ mb:{xs:'50%', sm:'10%'}, maxWidth:{xl:'1680px'} }}>
                    {
                        error &&
                        <Alert variant={'outlined'} severity={'error'} sx={{mx: 10}}>{error}</Alert>
                    }
                    {alerts.length === 0 ? (
                        <Typography variant="h6">Aucune alerte pour le moment.</Typography>
                    ) : (
                        alerts.map((alert, index) => (
                            <Alertnotes
                                key={index}
                                title={alert.title}
                                date={`Levée prévue le ${new Date(alert.end_date).toLocaleDateString('fr-FR')}`}
                                message={alert.description}
                                link={alert.url}
                                icon={alertIcons[alert.type] || ''}
                            />
                        ))
                    )}
                </Container>
        </Sidebar>


    );
}
