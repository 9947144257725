import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import Sidebar from "../Sidebar/Sidebar";
import {Alert, Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import Heroband from "../../components/Hero/Hero";
import {currentMunicipalityInterface} from "../../App";
import Snackbar from '@mui/material/Snackbar';
import CurrentUserContext from "../../context/CurrentUserContext";
import Apicall from "../../libs/Apicall";
import {CitizenFormData} from "./Citizenonboarding";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Grid from "@mui/material/Grid";
import Baseinfosform from "./OnboardingFormParts/Baseinfosform";
import AddressForm from "./OnboardingFormParts/AddressForm";
import Alertspreferences from "./OnboardingFormParts/Alertspreferences";
import Confirmlocationform from "./OnboardingFormParts/Confirmlocationform";
import Button from "@mui/material/Button";
import Alerttypes from "../../libs/Alerttypes";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";

interface propsInterface {
    municipality: currentMunicipalityInterface;
}
interface userInterface {
    id: number;
    created_at: string;
    firstname: string;
    lastname: string;
    email: string;
    role: string;
}

export default function Myprofile({municipality}: propsInterface) {
    const currentuser:userInterface = useContext(CurrentUserContext) as userInterface;
    const [error, setError] = useState('');
    const [alerts, setAlerts] = useState<string[]>([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [citizenInfos, setCitizenInfos] = useState<CitizenFormData>({
        id: 0,
        city_id: 0,
        firstname: "",
        lastname: "",
        phone_home: '',
        phone_mobile: '',
        email: "",
        address: "",
        apartment: "",
        city: "",
        postal_code: "",
        password: "",
        alert_sms: false,
        alert_email: true,
        alert_voice: false,
        latitude: null,
        longitude: null,
    });

    const fieldTranslations: Record<keyof CitizenFormData, string|number> = {
        id: 0,
        city_id: "",
        firstname: "Prénom",
        lastname: "Nom",
        email: "Adresse courriel",
        phone_home: "Numéro de téléphone",
        phone_mobile: "Numéro de cellulaire",
        address: "Adresse",
        apartment: "Appartement",
        city: "Ville",
        postal_code: "Code postal",
        password: "Mot de passe",
        confirmPassword: "",
        alert_sms: "",
        alert_email: "",
        alert_voice: "",
        latitude: "",
        longitude: "",
    };
    const selectAllAlerts = () => {
        setAlerts(Alerttypes.map(alert => alert.key));
    };

    const deselectAllAlerts = () => {
        setAlerts([]);
    };

    const handleToggleChange = (type: string, value: string) => {
        if (alerts.includes(value)) {
            setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert !== value));
        } else {
            setAlerts((prevAlerts) => [...prevAlerts, value]);
        }
    };

    useEffect(() => {
        const apicall = new Apicall();
        apicall.call('POST', 'me', {city_id: municipality.id}).then((response) => {
            if(response.status === 'OK') {
                setCitizenInfos(response.data.me);
                setAlerts(response.data.alerttypes);
            }else{
                setError(String(response.error));
            }
        });
    });

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        let formattedValue = value;
        if (name === "phone_home" || name === "phone_mobile") {
            formattedValue = formatPhoneNumber(value);
        }
        setCitizenInfos((prev) => (
                {...prev, [name]: formattedValue}
            )
        );
    };

    const formatPhoneNumber = (value: string) => {
        const onlyNums = value.replace(/[^\d]/g, '');
        if (onlyNums.length < 4) return onlyNums;
        if (onlyNums.length < 7) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
        return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
    };


    const [activeStep, setActiveStep] = useState(0);

    const steps = ['Informations de base', 'Adresse', 'Préférences d\'alerte', 'Nouveau mot de passe', 'Confirmez votre position'];

    const handleSave = () => {
        let errorMessage = "";

        // Validation pour les différentes étapes :
        switch(activeStep) {
            case 0:
                const requiredFields: Array<keyof CitizenFormData> = ["firstname", "lastname", "email"];
                for (let field of requiredFields) {
                    if (!citizenInfos[field]) {
                        errorMessage += `${fieldTranslations[field]} est obligatoire. `;
                    }
                }

                if (!citizenInfos.phone_home && !citizenInfos.phone_mobile) {
                    errorMessage += "Au moins un des champs Numéro de téléphone ou Numéro de cellulaire doit être renseigné. ";
                }
                break;
            case 1:
                const requiredAddressFields: Array<keyof CitizenFormData> = ["address", "city", "postal_code"];
                for (let field of requiredAddressFields) {
                    if (!citizenInfos[field]) {
                        errorMessage += `${fieldTranslations[field]} est obligatoire. `;
                    }
                }
                break;
            case 3:
                if (citizenInfos.password.length < 6 && citizenInfos.password.length > 0) {
                    errorMessage = "Le mot de passe doit avoir au moins 6 caractères. ";
                }
                break;
            case 4:
                if (citizenInfos.latitude === null || citizenInfos.longitude === null) {
                    errorMessage = "Veuillez confirmer votre position.";
                }
                break;
            default:
                break;
        }

        if (errorMessage) {
            setError(errorMessage);
        } else {
            setError('');

            // Appel API pour sauvegarder les informations :
            const apicall = new Apicall();
            apicall.call('POST', 'me/update', {citizenInfos, alerts})
                .then((response) => {
                    if (response.status === 'OK') {
                        setOpenSnackbar(true);
                    } else {
                        setError(String(response.error));
                    }
                })
                .catch(() => {
                    setError("Une erreur est survenue lors de l'enregistrement. Veuillez réessayer.");
                });
        }
    }

    return (
        <Sidebar title={'Page '+municipality.default_city_name} currentuser={currentuser} municipality={municipality}>
            <Heroband image={'../images/'+municipality.splash_background}/>
            <Container className={'wrapper-board'}>
                <Container className='head-content'>
                    <Typography variant={'h3'} className='page-title' sx={{ fontWeight:'900', color:'#FFB800', lineHeight:'55px', mb:2 }}>Mon profil</Typography>
                </Container>

                <Container className={'my-profile'} sx={{ mb:'10%'}} >
                    <Grid item xs={12} sx={{pb:4, mb: '50px'}}>
                        <Stepper className='step-container' activeStep={activeStep} orientation={window.innerWidth <= 768 ? "vertical" : "horizontal"}>
                            {steps.map((label, index) => (
                                <Step key={label} onClick={() => setActiveStep(index)} sx={{cursor: 'pointer'}}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                    {error && (
                        <Grid item xs={12}>
                            <Alert severity="error">{error}</Alert>
                        </Grid>
                    )}
                    {/* Form */}
                    {activeStep === 0 && (
                        <Baseinfosform formData={citizenInfos} handleInputChange={handleInputChange} />
                    )}

                    {activeStep === 1 && (
                        <AddressForm formData={citizenInfos} handleInputChange={handleInputChange} />
                    )}

                    {activeStep === 2 && (
                        <Alertspreferences canUseVoiceMsg={municipality.can_use_voice_msg} formData={citizenInfos} alerts={alerts} handleToggleChange={handleToggleChange} setFormData={setCitizenInfos} selectAllAlerts={selectAllAlerts} deselectAllAlerts={deselectAllAlerts} />
                    )}

                    {activeStep === 3 && (
                        <React.Fragment>
                            <Typography sx={{color: '#FFB800', fontSize:'24px', fontFamily: 'Nunito Sans', fontWeight: 800, px:3, pb: 1}}>Nouveau mot de passe</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField fullWidth type="password" label="Nouveau mot de passe" variant="outlined" value={citizenInfos.password} name="password" onChange={handleInputChange} />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}

                    {activeStep === 4 && (
                        <Confirmlocationform formData={citizenInfos} handleInputChange={handleInputChange} default_lat={municipality.defaultlat} default_lng={municipality.defaultlng} />
                    )}

                    {/* Controls */}
                    <Grid container spacing={2} sx={{pt:4, pb:4, mt:{xs:'0', md:'64px'}, }}>
                        <Grid item xs={12} sm={4}>
                            <Button className={'btn btn-blue'} variant="contained" color="primary" onClick={handleSave}>Sauvegarder</Button>
                        </Grid>
                    </Grid>
                    {/*<Footer/>*/}
                </Container>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000} // 6 secondes avant la fermeture automatique
                    onClose={() => setOpenSnackbar(false)}
                    message="Informations sauvegardées avec succès"
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpenSnackbar(false)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </Container>
        </Sidebar>
    )
}
