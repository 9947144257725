import React, {useEffect, useState} from 'react';
import './_App.scss';
import {Routes, Route, BrowserRouter, useNavigate} from "react-router-dom";
import UnAuthGuard from "./guard/UnAuthGuard";
import AuthGuard from "./guard/AuthGuards";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// VIEWS
import Login from './views/Auth/Login';
import Homepage from "./views/Homepage/Homepage";
import Newalert from "./views/Alert/Newalert";
import Alertlist from "./views/Alert/Alertlist";
import Mymunicipality from "./views/Mymunicipality/Mymunicipality";
import Citizenslist from "./views/Citizens/Citizenslist";
import Newcitizen from "./views/Citizens/Newcitizen";
import Citizenonboarding from "./views/Public/Citizenonboarding";
import Home from "./views/LandingPage/Home";
import DashboardSelector from "./views/Dashboard/DashboardSelector";
import Resetpassword from "./views/Auth/Resetpassword";
import OneAlert from "./views/Public/OneAlert";
import Myprofile from "./views/Public/Myprofile";
import Apicall from "./libs/Apicall";
import Editcitizen from "./views/Citizens/Editcitizen";
import PolitiqueConfidentialite from "./views/PolitiqueConfidentialite/PolitiqueConfidentialite";
import { ScrollToTop } from "./components/Layout/ScrollTop/ScrollToTop";

export interface currentMunicipalityInterface {
    default_city_name: string;
    defaultlat: number;
    defaultlng: number;
    id: number;
    logo: string;
    logo2: string;
    splash_background: string;
    can_use_voice_msg: boolean;
}

function MunicipalityHandler() {
    const navigate = useNavigate();
    const [currentMunicipality,setCurrentMunicipality] = useState<currentMunicipalityInterface|null>(null)
    const [hasError, setHasError] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const api = new Apicall();
        api.call('POST', 'config/get-municipality', {domain: window.location.host}).then((response) => {
            if (response.status !== 'OK') {
                setHasError(true);
                navigate('/');
            }else{
                setCurrentMunicipality(response.data);
            }
            setLoaded(true);
        });
    }, [navigate]);

    if (!loaded) {
        return null;
    }

    if (hasError) {
        return <Home />;
    }

    // Si tout est en ordre, rendu des enfants (qui sont les routes dans ce cas)
    return (
        <Routes>
            <Route path="/" element={currentMunicipality ? <UnAuthGuard trytogetuser={true} component={<Homepage municipality={currentMunicipality} />} /> : <Home />} />
            {currentMunicipality &&
                <>
                    {/*NOT PASSWORD PROTECTED*/}
                    <Route path="login" element={<UnAuthGuard component={<Login municipality={currentMunicipality} />} />}></Route>
                    <Route path="nouveau-not-de-passe" element={<UnAuthGuard component={<Resetpassword municipality={currentMunicipality} />} />}></Route>
                    <Route path="/s-enregistrer" element={<UnAuthGuard component={<Citizenonboarding municipality={currentMunicipality} />} />}></Route>
                    <Route path="/alerte/:slug" element={<UnAuthGuard component={<OneAlert municipality={currentMunicipality} />} />}></Route>
                    <Route path="/politique-confidentialite" element={<UnAuthGuard component={<PolitiqueConfidentialite municipality={currentMunicipality} />} />}></Route>

                    {/*PASSWORD PROTECTED*/}
                    <Route path="/dashboard" element={<AuthGuard roles={['ALL']} component={<DashboardSelector municipality={currentMunicipality} />} />}></Route>
                    <Route path="/nouvelle-alerte" element={<AuthGuard roles={['CITYADMIN','CITYREDACTOR','CITYADMINRESTRICTED']} component={<Newalert mode={'add'} municipality={currentMunicipality} />} />}></Route>
                    <Route path="/alertes" element={<AuthGuard roles={['CITYADMIN','CITYREDACTOR','CITYADMINRESTRICTED']} component={<Alertlist municipality={currentMunicipality} />} />}></Route>
                    <Route path="/alertes/modifier/:id" element={<AuthGuard roles={['CITYADMIN','CITYREDACTOR','CITYADMINRESTRICTED']} component={<Newalert mode={'edit'} municipality={currentMunicipality} />} />}></Route>
                    <Route path="/ma-ville" element={<AuthGuard roles={['CITYADMIN','CITYREDACTOR']} component={<Mymunicipality municipality={currentMunicipality} />} />}></Route>
                    <Route path="/ma-ville/:adminid" element={<AuthGuard roles={['CITYADMIN','CITYREDACTOR']} component={<Mymunicipality municipality={currentMunicipality} />} />}></Route>
                    <Route path="/citoyens" element={<AuthGuard roles={['CITYADMIN','CITYREDACTOR']} component={<Citizenslist municipality={currentMunicipality} />} />}></Route>
                    <Route path="/nouveau-citoyen" element={<AuthGuard roles={['CITYADMIN','CITYREDACTOR']} component={<Newcitizen municipality={currentMunicipality} />} />}></Route>
                    <Route path="/modifier-citoyen/:id" element={<AuthGuard roles={['CITYADMIN','CITYREDACTOR']} component={<Editcitizen municipality={currentMunicipality} />} />}></Route>
                    <Route path="/mon-profil" element={<AuthGuard roles={['ALL']} component={<Myprofile municipality={currentMunicipality} />} />}></Route>
                </>
            }
        </Routes>
    );
}

function App() {
    const defaultTheme = createTheme({
        palette: {
            mode: 'light',
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 768,
                md: 1024,
                lg: 1200,
                xl: 1440,
            },
        },
    });

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <BrowserRouter>
                <MunicipalityHandler />
                <ScrollToTop/>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
