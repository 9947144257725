import {
    CirculationIcon,
    FloodIcon,
    AlertSmallIcon,
    RoadClosedIcon,
    MeteoAlertIcon,
    ParkingIcon,
    BrokenPipesIcon,
    LanslideIcon,
    ImportantNoticeIcon,
    CloudyWaterIcon,
    SnowRemovalIcon,
    FireIcon,
    DomesticGarbageIcon,
    ClosedLocationIcon,
    WaterCutoffIcon,
    RadioactiveIcon,
    DeicingIcon,
    TornadoIcon,
    FireindexIcon,
    IcefieldIcon,
    HeatIcon,
    EnvironmentIcon,
    BoilingIcon,
    OpenedLocationIcon,
    WaterRestrictionIcon,
    ConfinementIcon,
    EvacuationIcon,
    VerglasIcon,
    NonConsommationEauIcon,
    FreezedWaterPipeIcon
} from "../components/LCIcons";

const Alerttypes = [
    {
        key: 'circulation',
        name: 'Circulation',
        icon: CirculationIcon,
        ogimage: 'OG-image-circulation.png'
    },
    {
        key: 'innondation',
        name: 'Innondation',
        icon: FloodIcon,
        ogimage: 'OG-image-inondation.png'
    },
    {
        key: 'alerte',
        name: 'Alerte',
        icon: AlertSmallIcon,
        ogimage: 'OG-image-alerte.png'
    },
    {
        key: 'routefermee',
        name: 'Route fermée',
        icon: RoadClosedIcon,
        ogimage: 'OG-image-route-fermee.png'
    },
    {
        key: 'alertemeteo',
        name: 'Alerte Météo',
        icon: MeteoAlertIcon,
        ogimage: 'OG-image-alerte-meteo.png'
    },
    {
        key: 'stationnement',
        name: 'Stationnement',
        icon: ParkingIcon,
        ogimage: 'OG-image-stationnement.png'
    },
    {
        key: 'brisaqueduc',
        name: 'Bris d\'aqueduc',
        icon: BrokenPipesIcon,
        ogimage: 'OG-image-bris-aqueduc.png'
    },
    {
        key: 'glissementterrain',
        name: 'Glissement de terrain',
        icon: LanslideIcon,
        ogimage: 'OG-image-glissement-terrain.png'
    },
    {
        key: 'avisimportant',
        name: 'Avis important',
        icon: ImportantNoticeIcon,
        ogimage: 'OG-image-avis-important.png'
    },
    {
        key: 'cloudywater',
        name: 'Eau brouillée',
        icon: CloudyWaterIcon,
        ogimage: 'OG-image-eau-brouillee.png'
    },
    {
        key: 'deneigement',
        name: 'Déneigement',
        icon: SnowRemovalIcon,
        ogimage: 'OG-image-deneigement.png'
    },
    {
        key: 'incendie',
        name: 'Incendie',
        icon: FireIcon,
        ogimage: 'OG-image-incendie.png'
    },
    {
        key: 'collectedomestique',
        name: 'Collecte domestique',
        icon: DomesticGarbageIcon,
        ogimage: 'OG-image-collecte-domestique.png'
    },
    {
        key: 'lieuferme',
        name: 'Lieu fermé',
        icon: ClosedLocationIcon,
        ogimage: 'OG-image-lieu-ferme.png'
    },
    {
        key: 'interruptioneau',
        name: 'Interruption de l\'eau',
        icon: WaterCutoffIcon,
        ogimage: 'OG-image-interruption-eau.png'
    },
    {
        key: 'matieredangereuse',
        name: 'Matière dangereuse',
        icon: RadioactiveIcon,
        ogimage: 'OG-image-matiere-dangereuse.png'
    },
    {
        key: 'deglacage',
        name: 'Déglaçage',
        icon: DeicingIcon,
        ogimage: 'OG-image-deglacage.png'
    },
    {
        key: 'tornade',
        name: 'Tornade',
        icon: TornadoIcon,
        ogimage: 'OG-image-tornade.png'
    },
    {
        key: 'indicedefeu',
        name: 'Indice de feu',
        icon: FireindexIcon,
        ogimage: 'OG-image-indice-feu.png'
    },
    {
        key: 'banquise',
        name: 'Banquise',
        icon: IcefieldIcon,
        ogimage: 'OG-image-banquise.png'
    },
    {
        key: 'chaleur',
        name: 'Chaleur',
        icon: HeatIcon,
        ogimage: 'OG-image-chaleur.png'
    },
    {
        key: 'environnement',
        name: 'Environnement',
        icon: EnvironmentIcon,
        ogimage: 'OG-image-environnement.png'
    },
    {
        key: 'avisebulition',
        name: 'Avis d’ébulition',
        icon: BoilingIcon,
        ogimage: 'OG-image-avis-ebullition.png'
    },
    {
        key: 'lieuouvert',
        name: 'Lieu ouvert',
        icon: OpenedLocationIcon,
        ogimage: 'OG-image-lieu-ouvert.png'
    },
    {
        key: 'restrictioneau',
        name: 'Restriction d’eau',
        icon: WaterRestrictionIcon,
        ogimage: 'OG-image-restriction-eau.png'
    },
    {
        key: 'confinement',
        name: 'Confinement',
        icon: ConfinementIcon,
        ogimage: 'OG-image-confinement.png'
    },
    {
        key: 'evacuation',
        name: 'Évacuation',
        icon: EvacuationIcon,
        ogimage: 'OG-image-evacuation.png'
    },
    {
        key: 'verglas',
        name: 'Verglas',
        icon: VerglasIcon,
        ogimage: 'OG-image-verglas.png'
    },
    {
        key: 'nonconsommationeau',
        name: 'Non consommation de l’eau',
        icon: NonConsommationEauIcon,
        ogimage: 'OG-image-non-consommation-eau.png'
    },
    {
        key: 'gelaqueduc',
        name: 'Gel d’aqueduc',
        icon: FreezedWaterPipeIcon,
        ogimage: 'OG-image-gel-aqueduc.png'
    }
]

export default Alerttypes;
