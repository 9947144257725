import LCSubtitle from "../../components/Typography/LCSubtitle";
import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import LCBigSquareBtn from "../../components/LCBigSquareBtn";
import {LCAlertIcon, LCUserIcon} from "../../components/LCIcons";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
export default function Quickactionspanel() {
    const navigate = useNavigate();

    const goto = (link:string) => {
        navigate(link);
    }
    return (
        <React.Fragment>
            <Box className={'actionpanels'}>
            <LCSubtitle>Actions rapides</LCSubtitle>
            <Paper elevation={0} sx={{minHeight: '255px', border: 0, backgroundColor: '#EFF2F3'}}>

                <Grid container>
                    <LCBigSquareBtn icon={<LCAlertIcon />} text={'Émettre une alerte'} onClick={() => {goto('/nouvelle-alerte')}} />
                    <LCBigSquareBtn icon={<LCUserIcon />} text={'Ajouter un citoyen'} onClick={() => {goto('/nouveau-citoyen')}} />
                </Grid>

            </Paper>
            </Box>
        </React.Fragment>
    );
}
