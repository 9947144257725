import React, {useEffect, useState} from 'react';
import LCContainer from "../_Base/LCContainer";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import 'moment/locale/fr';
import {useNavigate} from "react-router-dom";
import {
    TextField,
    TablePagination,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    SvgIcon
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import Apicall from "../../libs/Apicall";
import {currentMunicipalityInterface} from "../../App";
import { ReactComponent as Edition } from '../../assets/images/icons/edit.svg';
import { ReactComponent as Delete } from '../../assets/images/icons/close.svg';
import { ReactComponent as SMS } from '../../assets/images/icons/sms.svg';
import { ReactComponent as Email } from '../../assets/images/icons/mail.svg';
import { ReactComponent as Voice } from '../../assets/images/icons/phone.svg';
import LCSubtitle from "../../components/Typography/LCSubtitle";
import Box from "@mui/material/Box";

interface propsInterface {
    municipality: currentMunicipalityInterface
}

export interface CitizenInterface {
    id: number;
    firstname: string;
    lastname: string;
    code_postal: string;
    created_at: string;
    notif_sms: boolean;
    notif_vocal_msg: boolean;
    notif_email: boolean;
}
export default function Citizenslist({municipality}:propsInterface) {
    const navigate = useNavigate();
    const [citizens, setCitizens] = useState<CitizenInterface[]>([]);
    const [filteredCitizens, setFilteredCitizens] = useState<CitizenInterface[]>([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [citizenToDelete, setCitizenToDelete] = useState<CitizenInterface | null>(null);
    const rowsPerPage = 10;

    const handleAdd = () => {
        navigate('/nouveau-citoyen');
    };

    const handleEdit = (id:number) => {
        navigate(`/modifier-citoyen/${id}`);
    };

    const confirmDelete = (id:number) => {
        const citizen = citizens.find(c => c.id === id);
        if (citizen) {
            setCitizenToDelete(citizen);
            setOpenDialog(true);
        }
    };

    const handleDelete = async () => {
        if (citizenToDelete) {
            const apicall = new Apicall();
            await apicall.call('POST', `citizens/delete`, {id: citizenToDelete.id});

            // Mise à jour de la liste des citoyens après suppression
            const updatedCitizens = citizens.filter(c => c.id !== citizenToDelete.id);
            setCitizens(updatedCitizens);

            setOpenDialog(false);
            setCitizenToDelete(null);
        }
    };

    useEffect(() => {
        const apicall = new Apicall();
        apicall.call('GET', 'citizens').then((response) => {
            setCitizens(response.data);
        })
    }, []);

    useEffect(() => {
        if (search === '') {
            setFilteredCitizens(citizens);
        } else {
            const searchTerm = search.toLowerCase();
            const results = citizens.filter(citizen =>
                (citizen.firstname.toLowerCase() + " " + citizen.lastname.toLowerCase()).includes(searchTerm) ||
                citizen.code_postal.toLowerCase().includes(searchTerm)
            );
            setFilteredCitizens(results);
        }
    }, [search, citizens]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    return (
        <LCContainer title={'Citoyens'}>
            <Grid container sx={{px:3, pb: 3}}>
                <div style={{width: '100%'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{borderRadius: 20, my:3}}
                        onClick={handleAdd}
                        className={'btn btn-blue btn-icon btn-icon-plus'}
                    >
                        Ajouter un citoyen
                    </Button>
                </div>
                <div style={{width: '100%'}}>
                    <Box className={'citizen-head'}>
                        <LCSubtitle>Citoyens</LCSubtitle>
                        <TextField
                            variant="outlined"
                            placeholder="Rechercher"
                            value={search}
                            onChange={handleSearchChange}
                            sx={{ borderRadius: '25px', float: 'right', marginBottom: '10px', width: {xs:'65%', md:'30%'} }}
                            className={'fields-container field-search'}
                        />
                    </Box>

                    <TableContainer component={Paper} className={"blocks"}>
                        <Table className={'citizen-table'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={"center"} sx={{fontWeight: '700!important', fontSize:'14px', lineHeight:'20px'}}>Nom</TableCell>
                                    <TableCell align={"center"} sx={{fontWeight: '700!important', fontSize:'14px', lineHeight:'20px'}}>Secteur</TableCell>
                                    <TableCell align={"center"} sx={{fontWeight: '700!important', fontSize:'14px', lineHeight:'20px'}}>Date d'inscription</TableCell>
                                    <TableCell align={"center"} sx={{fontWeight: '700!important', fontSize:'14px', lineHeight:'20px'}}>Canaux</TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredCitizens.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((citizen) => (
                                    <TableRow key={citizen.id}>
                                        <TableCell className="name" sx={{fontWeight: '800!important', fontSize:'18px', lineHeight:'24px', color:'#00004F'}}><strong>{citizen.firstname + " " + citizen.lastname}</strong></TableCell>
                                        <TableCell className="postal">{citizen.code_postal}</TableCell>
                                        <TableCell className="date">{'Inscrit depuis le ' + moment(citizen.created_at).locale('fr').format('D MMMM YYYY')}</TableCell>
                                        <TableCell className="notif">
                                            {citizen.notif_sms ? <IconButton size="small">  <SvgIcon component={SMS} inheritViewBox /> </IconButton> : null}
                                            {citizen.notif_vocal_msg ? <IconButton size="small"> <SvgIcon component={Voice} inheritViewBox /> </IconButton> : null}
                                            {citizen.notif_email ? <IconButton size="small"><SvgIcon component={Email} inheritViewBox /> </IconButton> : null}
                                        </TableCell>
                                        <TableCell className="actions" align={"right"}>
                                            <IconButton color="primary" onClick={() => {handleEdit(citizen.id)}}> <SvgIcon component={Edition} inheritViewBox /> </IconButton>
                                            <IconButton color="error" onClick={() => {confirmDelete(citizen.id)}}> <SvgIcon component={Delete} inheritViewBox /> </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            component="div"
                            count={filteredCitizens.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, newPage) => setPage(newPage)}
                        />
                    </TableContainer>
                </div>
            </Grid>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle>{"Confirmer la suppression"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voulez-vous vraiment supprimer le citoyen <strong>{`${citizenToDelete?.firstname} ${citizenToDelete?.lastname}`}</strong>? Cette action est irréversible.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </LCContainer>
    );
}
