import {currentMunicipalityInterface} from "../../App";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import Heroband from "../../components/Hero/Hero";
import {Container} from "@mui/material";
import Sidebar from "../Sidebar/Sidebar";

interface propsInterface {
    municipality: currentMunicipalityInterface;
}
export default function PolitiqueConfidentialite({municipality}:propsInterface) {

    return (
        <Sidebar title={'Page '+municipality.default_city_name} municipality={municipality}>
            <Heroband image={'../images/'+municipality.splash_background} />
            <Container>
                <Typography className='page-title' sx={{ fontWeight:'900', color:'#FFB800', lineHeight:'55px' }}>Politique de Confidentialité</Typography>
            </Container>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            Cette politique s'applique au site :{" "}
                            <a href="https://liencitoyen.com/">https://liencitoyen.com/</a>
                        </Typography>
                        <Typography variant="subtitle1">
                            Date de dernière mise à jour : 6 octobre 2023
                        </Typography>
                        <Typography variant="h5" sx={{pt:3}}>1. COLLECTE DE RENSEIGNEMENTS PERSONNELS</Typography>
                        <Typography variant="body1">
                            Nous collectons les renseignements personnels suivants :
                            <ul>
                                <li>Nom</li>
                                <li>Prénom</li>
                                <li>Adresse postale</li>
                                <li>Code postal</li>
                                <li>Adresse électronique</li>
                                <li>Numéro de téléphone/de télécopieur</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1">
                            Les renseignements personnels que nous collectons sont recueillis par
                            l'entremise des modes de collecte décrits ci-bas, dans les sections
                            "Formulaires et modes de collecte" et suivantes.
                        </Typography>
                        <Typography variant="h5" sx={{pt:3}}>2. FORMULAIRES ET MODES DE COLLECTE</Typography>
                        <Typography variant="body1">
                            Vos renseignements personnels sont collectés par l'entremise des méthodes suivantes :
                            <ul>
                                <li>Formulaire d'inscription au site Web</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1">
                            Nous utilisons les renseignements ainsi collectés pour les finalités suivantes :
                            <ul>
                                <li>Contact</li>
                            </ul>
                        </Typography>
                        <Typography variant="h5" sx={{pt:3}}>3. INTERACTIVITÉ</Typography>
                        <Typography variant="body1">
                            Vos renseignements personnels sont également collectés par le biais de
                            l'interactivité pouvant s'établir entre vous et notre site Web. Ces
                            renseignements sont collectés par les moyens suivants :
                            <ul>
                                <li>Correspondance</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1">
                            Nous utilisons les renseignements ainsi collectés pour les finalités suivantes :
                            <ul>
                                <li>Contact</li>
                            </ul>
                        </Typography>
                        <Typography variant="h5" sx={{pt:3}}>4. PARTAGE DES RENSEIGNEMENTS PERSONNELS</Typography>
                        <Typography variant="body1">
                            Les renseignements personnels collectés par le site ne sont transmis à
                            aucun tiers et ne sont traités que par nous.
                        </Typography>
                        <Typography variant="h5" sx={{pt:3}}>5. DURÉE DE CONSERVATION DES RENSEIGNEMENTS PERSONNELS</Typography>
                        <Typography variant="body1">
                            Le responsable du traitement des renseignements personnels conservera dans
                            ses systèmes informatiques du site et dans des conditions raisonnables de
                            sécurité l'ensemble des renseignements personnels collectés pour une durée de :
                            Durant toute la période où l'utilisateur est inscrit.
                        </Typography>
                        <Typography variant="h5" sx={{pt:3}}>6. HÉBERGEMENT DES RENSEIGNEMENTS PERSONNELS</Typography>
                        <Typography variant="body1">
                            Notre site est hébergé par : Studio Cognitif inc, dont le siège est situé
                            à l'adresse ci-après :
                            710, rue Notre-Dame Sud, Louiseville, Qc, J5V 1Y9
                        </Typography>
                        <Typography variant="body1">
                            L'hébergeur peut être contacté au numéro de téléphone suivant : 819 498-8377.
                            Les renseignements que nous collectons et traitons sont exclusivement hébergés
                            et traités au Canada.
                        </Typography>
                        <Typography variant="h5" sx={{pt:3}}>7. RESPONSABLE DU TRAITEMENT DES RENSEIGNEMENTS PERSONNELS</Typography>
                        <Typography variant="body1">
                            <strong>a) Le responsable du traitement des renseignements personnels</strong>
                        </Typography>
                        <Typography variant="body1">
                            Le responsable du traitement des renseignements personnels est : Kevin Teasdale-Dubé.
                            Il peut être contacté de la manière suivante :
                            <ul>
                                <li>par courriel à info@liencitoyen.com.ca</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1">
                            Le responsable du traitement des renseignements personnels est chargé de
                            déterminer les finalités et les moyens mis au service du traitement des
                            renseignements personnels
                        </Typography>
                        <Typography variant="body1" sx={{pt:2}}>
                            <strong>b) Obligations du responsable du traitement des renseignements personnels</strong>
                        </Typography>
                        <Typography variant="body1">
                            Le responsable du traitement des renseignements personnels s'engage à
                            protéger les renseignements personnels collectés, à ne pas les transmettre à
                            des tiers sans que vous n'en ayez été informé(e) et à respecter les finalités
                            pour lesquelles ces renseignements ont été collectés.
                        </Typography>
                        <Typography variant="body1">
                            De plus, le responsable du traitement des renseignements personnels s'engage
                            à vous aviser en cas de rectification ou de suppression des renseignements
                            personnels, à moins que cela n'entraîne pour lui des formalités, coûts ou
                            démarches disproportionnés
                        </Typography>
                        <Typography variant="body1">
                            Dans le cas où l'intégrité, la confidentialité ou la sécurité de vos
                            renseignements personnels est compromise, le responsable du traitement
                            s'engage à vous en informer par tout moyen.
                        </Typography>
                        <Typography variant="h5" sx={{pt:3}}>8. DROIT D'OPPOSITION ET DE RETRAIT</Typography>
                        <Typography variant="body1">
                            Vous avez le droit de vous opposer au traitement de vos renseignements
                            personnels par le site ("droit d'opposition"). Vous avez également le droit de
                            demander à ce que vos renseignements personnels ne figurent plus, par exemple,
                            dans une liste de diffusion ("droit de retrait").
                        </Typography>
                        <Typography variant="body1">
                            Afin de formuler une opposition au traitement de vos renseignements personnels
                            ou demander le retrait de vos renseignements personnels, vous devez suivre la
                            procédure suivante :
                            <ul>
                                <li>Par courriel à info@liencitoyen.com.ca</li>
                            </ul>
                        </Typography>
                        <Typography variant="h5" sx={{pt:3}}>9. DROIT D'ACCÈS, DE RECTIFICATION ET DE SUPPRESSION</Typography>
                        <Typography variant="body1">
                            Vous pouvez prendre connaissance, mettre à jour, modifier ou demander la
                            suppression des renseignements vous concernant, en respectant la procédure
                            ci-après énoncée :
                            <ul>
                                <li>Par courriel à info@liencitoyen.com.ca</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1">
                            Si vous en possédez, vous avez le droit de demander la suppression de votre
                            espace personnel en suivant la procédure suivante :
                            <ul>
                                <li>Par courriel à info@liencitoyen.com</li>
                            </ul>
                        </Typography>
                        <Typography variant="h5" sx={{pt:3}}>10. SÉCURITÉ</Typography>
                        <Typography variant="body1">
                            Les informations personnelles que nous collectons sont conservées dans un
                            environnement sécurisé. Les personnes travaillant pour nous sont tenues de
                            respecter la confidentialité de vos informations.
                        </Typography>
                        <Typography variant="body1">
                            Pour assurer la sécurité de vos informations personnelles, nous avons recours
                            aux mesures suivantes :
                            <ul>
                                <li>Protocole SSL (Secure Sockets Layer)</li>
                                <li>Gestion des accès - personne autorisée</li>
                                <li>Gestion des accès - personne concernée</li>
                                <li>Identifiant/mot de passe</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1">
                            Nous nous engageons à maintenir un haut degré de confidentialité en intégrant
                            les dernières innovations technologiques permettant d'assurer la
                            confidentialité de vos transactions. Toutefois, comme aucun mécanisme n'offre
                            une sécurité maximale, une part de risque est toujours présente lorsqu'on
                            utilise Internet pour transmettre des informations personnelles.
                        </Typography>
                        <Typography variant="h5" sx={{pt:3}}>11. CONDITIONS DE MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</Typography>
                        <Typography variant="body1">
                            La présente politique de confidentialité peut être consultée à tout moment à
                            l'adresse ci-après indiquée :
                            <ul>
                                <li><a href="https://louiseville.liencitoyen.com/politique-confidentialite">https://louiseville.liencitoyen.com/politique-confidentialite</a></li>
                            </ul>
                        </Typography>
                        <Typography variant="body1">
                            Nous nous réservons le droit de la modifier afin de garantir sa conformité
                            avec le droit en vigueur.
                        </Typography>
                        <Typography variant="body1">
                            Par conséquent, vous êtes invité(e) à venir consulter régulièrement cette
                            politique de confidentialité afin de vous tenir informé(e) des derniers
                            changements qui lui seront apportés.
                        </Typography>
                        <Typography variant="h5" sx={{pt:3}}>12. ACCEPTATION DE LA POLITIQUE DE CONFIDENTIALITÉ</Typography>
                        <Typography variant="body1">
                            En naviguant sur le site, vous attestez avoir lu et compris la présente
                            politique de confidentialité et en acceptez les conditions, en ce qui
                            concerne plus particulièrement la collecte et le traitement de vos
                            renseignements personnels.
                        </Typography>
                        <Typography variant="h5" sx={{pt:3}}>13. LÉGISLATION</Typography>
                        <Typography variant="body1">
                            Nous nous engageons à respecter les dispositions législatives énoncées dans :
                            <ul>
                                <li>Loi sur la protection des renseignements personnels dans le secteur privé, RLRQ c P-39.1 ; et/ou</li>
                                <li>Loi sur la protection des renseignements personnels et les documents électroniques, LC 2000, c 5.</li>
                            </ul>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Sidebar>
    );
}
