import LCSubtitle from "../../components/Typography/LCSubtitle";
import LCPaper from "../../components/LCPaper";
import Box from "@mui/material/Box";
import React from "react";
interface propsInterface {
    logo: string;
}
export default function Welcomepanel({logo}:propsInterface) {
    return (
        <React.Fragment>
            <Box className={'welcomepanel'} >
                <LCSubtitle>Bienvenue</LCSubtitle>
                <LCPaper sx={{minHeight: '255px',display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Box component='img' src={`images/${logo}`} sx={{width: '60%'}} />
                </LCPaper>
            </Box>
        </React.Fragment>
    );
}
