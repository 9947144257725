import {Icon} from "@mui/material";

import AlertIcon from './svgIcons/LCAlertIcon.svg';
import MailIcon from './svgIcons/LCMailIcon.svg';
import UserIcon from './svgIcons/LCUserIcon.svg';
import RightarrowIcon from './svgIcons/LCRightarrow.svg';
import LeftarrowIcon from './svgIcons/LCLeftarrow.svg';

export { default as LoginIcon } from './svgIcons/LCLoginIcon.svg';
export { default as AlertIcon } from './svgIcons/LCAlertIcon.svg';
export { default as MailIcon } from './svgIcons/LCMailIcon.svg';
export { default as UserIcon } from './svgIcons/LCUserIcon.svg';
export { default as CirculationIcon } from './svgIcons/LCConeIcon.svg';
export { default as FloodIcon } from './svgIcons/LCFloodIcon.svg';
export { default as AlertSmallIcon } from './svgIcons/LCAlertSmallIcon.svg';
export { default as RoadClosedIcon } from './svgIcons/LCRoadClosedIcon.svg';
export { default as MeteoAlertIcon } from './svgIcons/LCMeteoAlert.svg';
export { default as ParkingIcon } from './svgIcons/LCParkingIcon.svg';
export { default as BrokenPipesIcon } from './svgIcons/LCBrokenPipes.svg';
export { default as LanslideIcon } from './svgIcons/LCLanslideIcon.svg';
export { default as ImportantNoticeIcon } from './svgIcons/LCImportantNotice.svg';
export { default as CloudyWaterIcon } from './svgIcons/LCCloudyWater.svg';
export { default as SnowRemovalIcon } from './svgIcons/LCSnowRemovalIcon.svg';
export { default as FireIcon } from './svgIcons/LCFireIcon.svg';
export { default as DomesticGarbageIcon } from './svgIcons/LCDomesticGarbage.svg';
export { default as ClosedLocationIcon } from './svgIcons/LCClosedLocation.svg';
export { default as WaterCutoffIcon } from './svgIcons/LCWaterCutOff.svg';
export { default as RadioactiveIcon } from './svgIcons/LCRadioactive.svg';
export { default as DeicingIcon } from './svgIcons/LCDeicing.svg';
export { default as TornadoIcon } from './svgIcons/LCTornado.svg';
export { default as FireindexIcon } from './svgIcons/LCFireindex.svg';
export { default as IcefieldIcon } from './svgIcons/LCIceField.svg';
export { default as HeatIcon } from './svgIcons/LCHeat.svg';
export { default as EnvironmentIcon } from './svgIcons/LCEnvironment.svg';
export { default as BoilingIcon } from './svgIcons/LCBoiling.svg';
export { default as OpenedLocationIcon } from './svgIcons/LCOpenedLocation.svg';
export { default as WaterRestrictionIcon } from './svgIcons/LCWaterRestriction.svg';
export { default as ConfinementIcon } from './svgIcons/LCConfinement.svg';
export { default as EvacuationIcon } from './svgIcons/LCEvacuation.svg';
export { default as VerglasIcon } from './svgIcons/LCVerglas.svg';
export { default as NonConsommationEauIcon } from './svgIcons/LCNonConsommationEau.svg';
export { default as FreezedWaterPipeIcon } from './svgIcons/LCFreezedWaterPipe.svg';

interface sizeProp {
    width?: number;
    height?: number;
    iconsrc?: any;
}
export function LCAlertIcon({width, height}:sizeProp) {
    return (
        <Icon sx={{width: width??60, height: height??45}} className={'mr-2'}>
            <img alt="alert" src={AlertIcon} />
        </Icon>
    );
}

export function LCMailIcon({width, height}:sizeProp) {
    return (
        <Icon sx={{width: width??60, height: height??45}} className={'mr-2'}>
            <img alt="mail" src={MailIcon} />
        </Icon>
    );
}

export function LCUserIcon({width, height}:sizeProp) {
    return (
        <Icon sx={{width: width??60, height: height??45}} className={'mr-2'}>
            <img alt="user" src={UserIcon} />
        </Icon>
    );
}

export function LCRightarrowIcon({width, height}:sizeProp) {
    return (
        <Icon sx={{width: width??60, height: height??45}} className={'mr-2'}>
            <img alt={'right'} src={RightarrowIcon} />
        </Icon>
    );
}

export function LCLeftarrowIcon({width, height}:sizeProp) {
    return (
        <Icon sx={{width: width??60, height: height??45}} className={'mr-2'}>
            <img alt={'left'} src={LeftarrowIcon} />
        </Icon>
    );
}

export function LCGenericIcon({width, height, iconsrc}:sizeProp) {
    return (
        <Icon sx={{width: width??60, height: height??45}} className={'mr-2'}>
            <img alt={'left'} src={iconsrc} />
        </Icon>
    );
}
