import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";
import {CitizenFormData} from "../Citizenonboarding";
interface propsInterface {
    formData: CitizenFormData;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export default function Baseinfosform({ formData, handleInputChange }: propsInterface) {
    return (
        <React.Fragment>
            <Typography variant={'h3'} className='page-title' sx={{color: '#FFB800', /*fontSize:'24px', fontFamily: 'Nunito Sans', fontWeight: 800, px:3, pb: 1, pl: 0,*/ }}>Informations de base</Typography>
            <Grid container spacing={{md: 2}} >
                <Grid sx={{marginTop:'30px',}} item xs={12} md={6}>
                    <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:'10px' }}>Prénom*</Typography>
                    <TextField fullWidth /*label="Prénom"*/ placeholder="Prénom" variant="outlined" name="firstname" value={formData.firstname} onChange={handleInputChange} />
                </Grid>
                <Grid sx={{marginTop:'30px',}} item xs={12} md={6}>
                    <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:'10px' }}>Nom*</Typography>
                    <TextField fullWidth /*label="Nom"*/ placeholder="Nom" variant="outlined"  name="lastname" value={formData.lastname} onChange={handleInputChange} />
                </Grid>

                <Grid sx={{marginTop:'85px'}} item xs={12}>
                    <Typography variant={'h4'} sx={{color: '#00004F', /*fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px'*/ pb:'10px' }}>Pour recevoir vos alertes</Typography>
                    <Typography>Au moins un numéro et une adresse courriel sont requis</Typography>
                </Grid>

                <Grid sx={{marginTop:'30px',}} item xs={12} md={6}>
                    <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:'10px' }}>Numéro de cellulaire</Typography>
                    <TextField fullWidth /*label="Numéro de cellulaire"*/ placeholder="Numéro de cellulaire" variant="outlined"  name="phone_mobile" value={formData.phone_mobile} onChange={handleInputChange} inputProps={{ maxLength: 14 }} />
                </Grid>
                <Grid sx={{marginTop:'30px',}} item xs={12} md={6}>
                    <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:'10px' }}>Numéro de téléphone</Typography>
                    <TextField fullWidth /*label="Numéro de téléphone"*/ placeholder="Numéro de téléphone" variant="outlined"  name="phone_home" value={formData.phone_home} onChange={handleInputChange} inputProps={{ maxLength: 14 }} />
                </Grid>
                <Grid sx={{marginTop:'30px',}} item xs={12} md={6}>
                    <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:'10px' }}>Adresse courriel*</Typography>
                    <TextField fullWidth /*label="Adresse courriel"*/ placeholder="Adresse courriel" variant="outlined"  name="email" value={formData.email} onChange={handleInputChange} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
