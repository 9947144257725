import * as React from "react";
import Box from "@mui/material/Box";
import {SvgIcon} from "@mui/material";
import { ReactComponent as Interogation } from '../../assets/images/interogation.svg';
import MontreSm from '../../assets/images/apple-watch-sm.png';
import MontreMd from '../../assets/images/apple-watch-md.png';
import MontreXl from '../../assets/images/apple-watch-xl.png';
import { ReactComponent as Banner } from '../../assets/images/sprite.svg';
import { motion } from "framer-motion";

export default function BlockInfosLanding () {
    return (

        <Box className={'blockinfos-landing'}>
            <motion.div className='blockinfos-landing--top'
                        initial={{ opacity: 0, transform: 'translateY(100px)' }}
                        whileInView={{ opacity: 1, transform: 'translateY(0px)' }}
                        transition={{ ease: "easeOut", duration: 0.75}}
                        viewport={{ once: true }}>
                <SvgIcon component={Banner} inheritViewBox />
            </motion.div>

            <div className='blockinfos-landing--middle'>
                <div className='blockinfos-landing--content'>
                    <motion.div className="blockinfos-landing--content-text"
                                initial={{ opacity: 0, transform: 'translateY(100px)' }}
                                whileInView={{ opacity: 1, transform: 'translateY(0px)' }}
                                transition={{ ease: "easeOut", duration: 0.5}}
                                viewport={{ once: true }}>
                        <h3 className='section-title'>Une application au service de vos citoyens</h3>
                        <ul className='blockinfos-list'>
                            <li>Avis d'urgence</li>
                            <li>Nouvelles <span><SvgIcon component={Interogation} inheritViewBox /></span></li>
                            <li>Calendrier <span><SvgIcon component={Interogation} inheritViewBox /></span></li>
                            <li>Compte de taxes <span><SvgIcon component={Interogation} inheritViewBox /></span></li>
                            <li>Et bien plus à venir</li>
                        </ul>
                    </motion.div>
                </div>
            </div>

            <div className='blockinfos-landing--bottom'>
                <motion.div className='cta'
                            initial={{ opacity: 0, transform: 'translateY(100px)' }}
                            whileInView={{ opacity: 1, transform: 'translateY(0px)' }}
                            transition={{ ease: "easeOut", duration: 0.25}}
                            viewport={{ once: true }}>
                    <button className='btn btn-blue btn-small btn-shadow' onClick={() => window.location.href = 'mailto:liencitoyen@cognitif.ca' }>Demander une estimation</button>
                </motion.div>
                <motion.div className='image-content'
                            initial={{ opacity: 0, transform: 'translateX(100%)' }}
                            whileInView={{ opacity: 1, transform: 'translateX(0)' }}
                            transition={{ ease: "easeOut", duration: 0.75}}
                            viewport={{ once: true }}>
                    <img src={MontreXl} srcSet={`${MontreSm} 300w, ${MontreMd} 768w, ${MontreXl} 1280w`} alt="Bonjour, Liens citoyen"/>
                </motion.div>
            </div>
        </Box>
    );
}
