import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import {CitizenFormData} from "../Citizenonboarding";

interface propsInterface {
    formData: CitizenFormData;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export default function AddressForm({ formData, handleInputChange }: propsInterface) {
    return (
        <React.Fragment>
            <Typography variant={'h3'} sx={{color: '#FFB800', fontSize:'24px', fontFamily: 'Nunito Sans', fontWeight: 800, px:3, pb: 1, pl: 0, }}>Adresse</Typography>
            <Grid container spacing={{md:2}}>
                <Grid sx={{marginTop:'30px',}} item xs={12} md={6}>
                    <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:'10px' }}>Adresse*</Typography>
                    <TextField fullWidth /*label="Adresse"*/ placeholder="Adresse" variant="outlined" name="address" value={formData.address} onChange={handleInputChange} />
                </Grid>
                <Grid sx={{marginTop:'30px',}} item xs={12} md={6}>
                    <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:'10px' }}>Appartement, bureau, local</Typography>
                    <TextField fullWidth /*label="Appartement, bureau, local"*/ placeholder="Appartement, bureau, local" variant="outlined" name="apartment" value={formData.apartment} onChange={handleInputChange} />
                </Grid>
                <Grid sx={{marginTop:'30px',}} item xs={12} md={6}>
                    <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:'10px' }}>Ville*</Typography>
                    <TextField fullWidth /*label="Ville"*/ placeholder="Ville" variant="outlined"  name="city" value={formData.city} onChange={handleInputChange} />
                </Grid>
                <Grid sx={{marginTop:'30px',}} item xs={12} md={6}>
                    <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:'10px' }}>Code postal*</Typography>
                    <TextField fullWidth /*label="Code postal"*/ placeholder="Code postal" variant="outlined" name="postal_code" value={formData.postal_code} onChange={handleInputChange} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
