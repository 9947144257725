import React, {useEffect, useState} from 'react';
import {getProfile, currentUserInterface} from "../libs/Auth";
import {useNavigate} from "react-router-dom";
import {CurrentUserProvider} from "../context/CurrentUserContext";

interface propsInterface {
    component: React.JSX.Element;
    roles: String[];
}

const AuthGuard = ({component, roles}: propsInterface) => {
    const [status, setStatus] = useState(false);
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState({} as currentUserInterface);
    useEffect(() => {
        checkToken();
    }, [component]);

    const checkToken = async () => {
        const user = await getProfile();
        if(user){
            if(!roles.includes('ALL')){
                if(!roles.includes(user.role)){
                    navigate('/login');
                    return;
                }
            }
            setCurrentUser(user as currentUserInterface);
            setStatus(true);
        }else{
            navigate('/login');
        }
    }

    return status ? <React.Fragment><CurrentUserProvider value={currentUser}>{component}</CurrentUserProvider></React.Fragment> : <React.Fragment/>
}

export default AuthGuard;
