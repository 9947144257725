import axios from 'axios';
import * as ApiInterfaces from '../interfaces/Apicall';
import * as env from '../env';

class Apicall {
    return:ApiInterfaces.Response = {
        status: 'OK',
        data: null,
    };

    constructor() {
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.defaults.headers.common['Accept-Language'] = 'fr';
    }

    async setAuthorizationHeader():Promise<void> {
        const thetoken = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = `Bearer ${thetoken}`;

        return;
    }

    private resetReturn(){
        this.return = {
            status: 'OK',
            data: null
        };
    }

    async call(method: string, path: string, payload?: any):Promise<ApiInterfaces.Response> {
        await this.setAuthorizationHeader();
        this.resetReturn();
        if (method === 'GET') {
            return axios.get(env.apiUrl+`/api/${path}`).then((response) => {
                this.return.status = response.data.status;
                this.return.data = response.data.data;
                if(this.return.status !== 'OK') {
                    this.return.error = response.data.error;
                }
                return this.return;
            }).catch((error) => {
                this.return.status = 'ERROR';
                this.return.error = 'Un problème serveur est survenu';
                if(error.response?.data?.error){
                    this.return.error = error.response.data.error;
                }
                if(error.response?.data?.message){
                    this.return.error = error.response.data.message;
                }
                return this.return;
            });
        }

        return axios.post(env.apiUrl+`/api/${path}`, payload).then((response) => {
            this.return.status = response.data.status;
            this.return.data = response.data.data;
            if(this.return.status !== 'OK') {
                this.return.error = response.data.error;
            }
            return this.return;
        }).catch((error) => {
            this.return.status = 'ERROR';
            this.return.error = 'Un problème serveur est survenu';
            if(error.response?.data?.error){
                this.return.error = error.response.data.error;
            }
            if(error.response?.data?.message){
                this.return.error = error.response.data.message;
            }
            return this.return;
        });
    }
}

export default Apicall;
