import * as React from "react";
import Box from "@mui/material/Box";
import {Container, Divider, SvgIcon} from "@mui/material";
import { ReactComponent as Facebook } from '../../assets/images/icons/facebook.svg';


type Props = {
    title: string,
    message: string,
    date: string,
    link: string,
    icon:string;
}

function generateFacebookShareLink(url: string) {
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
}

const facebookShareLink = generateFacebookShareLink(window.location.href);

export default function Alertnotes ({ title, date, message, link, icon }: Props) {
    return(
        <Container className='alert'>
            <Box className='alert-header'>
                <Box className='alert-header__icon'>
                    <div dangerouslySetInnerHTML={{__html: icon}} className={'icon'} />
                </Box>
                <Box className='alert-header__content'>
                    <h4 className='title'>{title}</h4>
                    <p className='date'>{date}</p>
                </Box>
            </Box>
            <Box className='alert-content'>
                <p className='message'>{message}</p>

                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'flex-start', mt:'1.5rem', }}>
                    <a href={link} className='btn btn-yellow btn-shadow' style={{ marginRight: '20px' }}>Plus de détail</a>
                    <a href={facebookShareLink} target="_blank" className='share-button share-button' rel="noopener noreferrer" > <SvgIcon className='share share-shadow' component={Facebook} inheritViewBox /> </a>
                </Box>
            </Box>
            <Divider/>
        </Container>
    )
}
