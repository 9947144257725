import Typography from "@mui/material/Typography";
import React from "react";

type Props = {
    children: React.ReactNode
}
export default function LCSubtitle({children}: Props) {

    return (
        <Typography variant={'subtitle2'} sx={{color: '#00004F', fontSize: '16px', fontWeight: 800, fontFamily:'Nunito Sans', mb:'0.625rem'}} >{children}</Typography>
    )
}
