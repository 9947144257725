import React, {useContext, useEffect, useState} from 'react';
import LCContainer from '../_Base/LCContainer';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Button, Container, MenuItem} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LCSectorSelector from "../../components/LCSectorSelector";
import {
    LCGenericIcon,
} from "../../components/LCIcons";
import Alerttypes from '../../libs/Alerttypes';
import { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LCInputText from "../../components/LCInputText";
import 'dayjs/locale/fr-ca';
import {useNavigate, useParams} from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Apicall from "../../libs/Apicall";
import dayjs from "dayjs";
import { segmentsCounter } from "../../libs/Twilio";
import ButtonGroup from '@mui/material/ButtonGroup';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import {currentMunicipalityInterface} from "../../App";
import SendTestSMSModal from "../../components/SendTestSMSModal";
import SendTestVoiceCallModal from "../../components/SendTestVoiceCallModal";
import CurrentUserContext from "../../context/CurrentUserContext";
import Box from "@mui/material/Box";

interface AlertDetailsInterface {
    type: string;
    startdate: Dayjs | null;
    enddate: Dayjs | null;
    sector: any [];
    title: string;
    content: string;
    sms_content: string;
    voice_message_content: string;
    send_to_all: boolean;
}

interface CoordsInterface {
    lat: number;
    lng: number;
}

interface propsInterface {
    mode: string;
    municipality: currentMunicipalityInterface;
}
interface userInterface {
    id: number;
    created_at: string;
    firstname: string;
    lastname: string;
    email: string;
    role: string;
    hasCitizen?: boolean;
    alertTypes: string[];
}

interface defaultTextsInterface {
    type: string;
    label: string;
    title: string;
    description: string;
    sms_message: string;
    vocal_message: string;
}
export default function Newalert(props:propsInterface) {
    const currentuser:userInterface = useContext(CurrentUserContext) as userInterface;
    const [smsMessageCount, setSmsMessageCount] = useState(1);
    const [isSMSTestModalOpen, setIsSMSTestModalOpen] = useState(false);
    const [isVoiceCallTestModalOpen, setIsVoiceCallTestModalOpen] = useState(false);
    const [defaultTexts, setDefaultTexts] = useState<defaultTextsInterface[]>([])

    const handleSmsContentChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const messageCount = segmentsCounter(e.target.value);
        setSmsMessageCount(messageCount);

        setAlertDetails({...alertDetails, sms_content: e.target.value});
    }

    const [typeSelected, setTypeSelected] = useState('');
    const [alertDetails, setAlertDetails] = useState<AlertDetailsInterface>({
        type: '',
        title: '',
        startdate: dayjs(),
        enddate: null,
        sector: [],
        content: '',
        sms_content: '',
        voice_message_content: '',
        send_to_all: true,
    });
    const [sectorAppOpened, setSectorAppOpened] = useState(false);
    const [sector, setSector] = useState<CoordsInterface[]>([]);
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const routeParams = useParams();

    const [selectedContentLabel, setSelectedContentLabel] = useState('');

    const toggleTypeSelection = (key:string) => {
        if (typeSelected === key) {
            setTypeSelected('');
            setAlertDetails({...alertDetails, type:''});
        }else{
            setTypeSelected(key);
            setAlertDetails({...alertDetails, type:key});
        }
    }

    const returnToList = () => {navigate('/alertes')}
    const saveAlert = () => {
        let oktocontinue = true;
        if(alertDetails.title.length === 0){
            setError('Veuillez entrer le titre');
            oktocontinue = false;
        }
        if(alertDetails.type.length === 0){
            setError('Veuillez sélectionner un type d\'alerte');
            oktocontinue = false;
        }
        if(alertDetails.startdate === null){
            setError('Veuillez entrer la date d\'entré en vigueur');
            oktocontinue = false;
        }
        if(alertDetails.sector.length === 0 && !alertDetails.send_to_all){
            setError('Veuillez sélectionner le secteur');
            oktocontinue = false;
        }
        if(alertDetails.content.length === 0){
            setError('Veuillez entrer le détail de l\'alerte');
            oktocontinue = false;
        }
        if(oktocontinue){
            const apicall = new Apicall();
            const endpoint = props.mode === 'edit' ? 'update' : 'new';
            apicall.call('POST', `alerts/${endpoint}`, alertDetails).then(() => {
                returnToList();
            })
        }
    }

    const saveAndSendAlert = async() => {
        let oktocontinue = true;
        if(alertDetails.title.length === 0){
            setError('Veuillez entrer le titre');
            oktocontinue = false;
        }
        if(alertDetails.type.length === 0){
            setError('Veuillez sélectionner un type d\'alerte');
            oktocontinue = false;
        }
        if(alertDetails.startdate === null){
            setError('Veuillez entrer la date d\'entré en vigueur');
            oktocontinue = false;
        }
        if(alertDetails.sector.length === 0 && !alertDetails.send_to_all){
            setError('Veuillez sélectionner le secteur');
            oktocontinue = false;
        }
        if(alertDetails.content.length === 0){
            setError('Veuillez entrer le détail de l\'alerte');
            oktocontinue = false;
        }
        if(oktocontinue){
            const apicall = new Apicall();
            const endpoint = props.mode === 'edit' ? 'update' : 'new';
            const response = await apicall.call('POST', `alerts/${endpoint}`, alertDetails);
            apicall.call('POST', 'alerts/start-sending', {id: response.data.id}).then(() => {
                returnToList()
            })
        }
    }

    const setContentFromDefault = (payload:defaultTextsInterface) => {
        setSelectedContentLabel(payload.label);
        setAlertDetails(prev => ({
            ...prev,
            title: payload.title,
            content: payload.description,
            sms_content: payload.sms_message,
            voice_message_content: payload.vocal_message
        }));
    }

    useEffect(() => {
        const apicalldefaults = new Apicall();
        apicalldefaults.call('POST','alerts/defaults', {city_id: props.municipality.id}).then((response) => {
            setDefaultTexts(response.data);
        });
        if(props.mode === 'edit') {
            const apicall = new Apicall();
            apicall.call('POST', 'alerts/get-one', {id: routeParams.id}).then((response) => {
                if(response.status === 'OK'){
                    response.data.startdate = dayjs(response.data.start_date);
                    response.data.enddate = dayjs(response.data.end_date);
                    // Convert areapoints JSON string to object before setting the state
                    response.data.areapoints = JSON.parse(response.data.areapoints);
                    response.data.sector = response.data.areapoints;
                    response.data.content = response.data.description;
                    response.data.send_to_all = response.data.send_to_all === 1 || response.data.send_to_all === true;
                    // Update the local state with the response data
                    setAlertDetails(response.data);
                    // Update typeSelected and sector with the response data
                    setTypeSelected(response.data.type);
                    setSector(response.data.areapoints);
                }else{
                    navigate('/alertes'); //alerte inexistante ou n'a pas le droit de modifier cette alerte
                }
            })
        }else{
            setAlertDetails({
                type: '',
                title: '',
                startdate: dayjs(),
                enddate: null,
                sector: [],
                content: '',
                sms_content: '',
                voice_message_content: '',
                send_to_all: true,
            })
        }
    }, []);

    return(
        <LCContainer title={props.mode === 'edit' ? 'Modification d\'une alerte':'Nouvelle alerte'}>
            <Container className={'wrapper-board'}>
                <Dialog
                    open={error.length > 0}
                    onClose={() =>{setError('')}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{zIndex: 10000}}
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Erreur"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {error}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Typography sx={{color: '#FFB800', fontSize:'30px', fontFamily: 'Nunito Sans', fontWeight: 800, px:3, pb: 3}}>Type d'alerte</Typography>
                <Grid container sx={{px:3, pb: 3}}>
                    {Alerttypes.map((type) => {
                        if (currentuser.role === 'CITYADMINRESTRICTED' && !currentuser.alertTypes.includes(type.key)) {
                            return null;
                        }
                        return (
                            <Grid item md={4} xs={12} key={type.key}>
                                <Button startIcon={<LCGenericIcon height={30} width={30} iconsrc={type.icon}/>} sx={{
                                    justifyContent: "flex-start",
                                    backgroundColor: typeSelected === type.key?'#FFB800':'inherit',
                                    color: '#00004F',
                                    "&:hover": {
                                        backgroundColor:typeSelected === type.key?'#FFB800':'inherit'
                                    }}} fullWidth onClick={() => {toggleTypeSelection(type.key)}}>{type.name}</Button>
                            </Grid>
                        );
                    })}
                </Grid>
                <Typography sx={{color: '#FFB800', fontSize:'30px', fontFamily: 'Nunito Sans', fontWeight: 800, px:3, pb: 3}}>Détails de l'alerte</Typography>
                <Grid container sx={{px:3, pb: 3}}>
                    {/*LINE ONE*/}
                    <Grid container sx={{width:'100%',}}>
                        <Grid item md={4} xs={12} sx={{pt:2, pb:2,}}>
                            <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:1 }}>En vigueur à partir du</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr-ca">
                                <DatePicker value={alertDetails.startdate} /*label={'En vigueur à partir du'}*/ onChange={(e) => {setAlertDetails({...alertDetails, startdate:e as Dayjs})}} />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item md={4} xs={12} sx={{pt:2, pb:2,}}>
                            <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:1 }}>Levée prévue</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr-ca">
                                <DatePicker value={alertDetails.enddate} /*label={'Levée prévue'}*/ onChange={(e) => {setAlertDetails({...alertDetails, enddate:e as Dayjs})}} />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item md={4} xs={12} sx={{pt:2, pb:2,}}>
                            {/* 2. Ajoutez le composant de boutons radio pour choisir l'option */}
                            <FormControl >
                                <RadioGroup
                                    value={alertDetails.send_to_all ? "all" : "sector"}
                                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => (setAlertDetails({...alertDetails, send_to_all:e.target.value === "all"}))}
                                >
                                    <FormControlLabel value="all" control={<Radio />} label="Envoyer à tous" />
                                    <FormControlLabel value="sector" control={<Radio />} label="Sélectionner une zone géographique" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container sx={{mt:'16px', justifyContent:'flex-end',}}>
                        <Grid item md={4} xs={12}>
                            {/* 3. Si l'option "Envoyer à tous" est choisie, désactivez le bouton "Configurer le secteur" */}
                            <Button sx={{paddingLeft:'3.3125rem', paddingRight:'3.3125rem'}} className={'btn btn-blue'} size={'large'} variant={'contained'} onClick={() => {setSectorAppOpened(true)}} disabled={alertDetails.send_to_all}>
                                Configurer le secteur
                            </Button>
                        </Grid>
                    </Grid>
                    {
                        defaultTexts.filter(d => d.type === alertDetails.type).length > 0 &&
                        <Grid item xs={12} sx={{pt: 10}}>
                            <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', mb:2 }}>Contenu par défaut</Typography>
                            {
                                defaultTexts.map((def, index) => {
                                    if(def.type !== alertDetails.type){
                                        return null;
                                    }

                                    return (
                                        <Button
                                            className={def.label === selectedContentLabel ? "button-selected" : ""}
                                            key={index}
                                            onClick={() => setContentFromDefault(defaultTexts[index])}
                                            variant={'outlined'}
                                            sx={{mx:1}}>{def.label}</Button>
                                    )
                                })
                            }
                        </Grid>
                    }
                    <Grid item xs={12} sx={{pt: 3}}>
                        <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px' }}>Titre de l'alerte</Typography>
                        <LCInputText
                            margin="normal"
                            required
                            fullWidth
                            placeholder={"Titre"}
                            id="title"
                            name="title"
                            value={alertDetails.title}
                            onChange={(e:React.ChangeEvent<HTMLInputElement>) => setAlertDetails({...alertDetails, title: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{pt: 3}}>
                        <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px' }}>Details de l'alerte</Typography>
                        <LCInputText
                            margin="normal"
                            required
                            fullWidth
                            multiline
                            rows={10}
                            placeholder={"Détails de l'alerte"}
                            value={alertDetails.content}
                            onChange={(e:React.ChangeEvent<HTMLInputElement>) => setAlertDetails({...alertDetails, content: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{pt: 2}}>
                        <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px' }}>Contenu du message SMS</Typography>
                        <LCInputText
                            margin="normal"
                            required
                            fullWidth
                            placeholder={"Contenu du message SMS"}
                            value={alertDetails.sms_content}
                            onChange={handleSmsContentChange}
                        />
                        <Box sx={{mt:2, display:'flex', flexDirection:'column', }}>
                            <Typography variant="caption" sx={{pb:1}}>
                                Nbre de segments: {smsMessageCount}
                            </Typography>
                            <Button className="btn btn-small btn-outlined" variant="outlined" color="secondary" size={'small'} sx={{}} onClick={() => setIsSMSTestModalOpen(true)}>
                                Envoyer SMS test
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{pt: 3, mt:4}}>
                        <LCInputText
                            margin="normal"
                            required
                            fullWidth
                            multiline
                            rows={3}
                            placeholder={"Contenu du message vocal"}
                            value={alertDetails.voice_message_content}
                            onChange={(e:React.ChangeEvent<HTMLInputElement>) => setAlertDetails({...alertDetails, voice_message_content: e.target.value})}
                        />
                        <Box sx={{mt:2, display:'flex', flexDirection:'column' }}>
                            <Typography variant="body2" color="textSecondary" sx={{pb:1}}>
                                Le message commencera par 'Bonjour, alerte lien citoyen: '
                            </Typography>
                            <Button className="btn btn-small btn-outlined" variant="outlined" color="secondary" onClick={() => setIsVoiceCallTestModalOpen(true)} size={'small'}>
                                Envoyer un test vocal
                            </Button>
                        </Box>

                    </Grid>
                </Grid>
                <Grid container sx={{ px:3, marginTop:'100px', flexDirection:{xs:'column', sm:'row'} }}>
                    <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "flex-start" }}>
                        <Button className={'btn btn-red'} variant={'contained'} color={'error'} onClick={() => returnToList()}>Annuler</Button>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ width: {xs:'100%', md:'auto'}, marginTop:{xs:'2rem', sm:'0'}, display: "flex", justifyContent: "flex-end" }}>
                        {
                            (currentuser.role === 'CITYADMIN' || currentuser.role === 'CITYADMINRESTRICTED') ?
                                <CustomSaveButton save={() => saveAlert()} saveandsend={() => saveAndSendAlert()} />
                                :
                                <Button variant={'contained'} color={'primary'} onClick={() => {saveAlert()}}>Sauvegarder</Button>
                        }
                    </Grid>
                </Grid>
                {
                    sectorAppOpened &&
                    <LCSectorSelector onClose={(returnedpolyline) => {
                        if(returnedpolyline){
                            setSector(returnedpolyline);
                            setAlertDetails({...alertDetails, sector: returnedpolyline});
                        }else{
                            setAlertDetails({...alertDetails, sector: []});
                        }
                        setSectorAppOpened(false)
                    }}
                                      loadedSection={sector}
                                      default_lat={props.municipality.defaultlat as number}
                                      default_lng={props.municipality.defaultlng as number}

                    />
                }
                <SendTestSMSModal
                    isOpen={isSMSTestModalOpen}
                    onClose={() => setIsSMSTestModalOpen(false)}
                    // Vous pouvez passer le prop smsContent ici si nécessaire:
                    smsContent={alertDetails.sms_content}
                />
                <SendTestVoiceCallModal
                    isOpen={isVoiceCallTestModalOpen}
                    onClose={() => setIsVoiceCallTestModalOpen(false)}
                    content={alertDetails.voice_message_content}
                />
            </Container>
        </LCContainer>
    )
}

interface customBtnPropsInterface {
    save: () => void;
    saveandsend: () => void;
}
function CustomSaveButton({save, saveandsend}:customBtnPropsInterface) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const options = ['Sauvegarder', 'Enregistrer et envoyer'];

    const handleClick = () => {
        if (selectedIndex === 0) {
            save();  // Cette fonction doit être définie selon votre logique
        } else {
            saveandsend(); // Cette fonction doit être définie selon votre logique
        }
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            <ButtonGroup sx={{ boxShadow:0, width:'100%' }} variant="contained" ref={anchorRef} aria-label="split button">
                <Button className='btn-split btn-split-left' onClick={handleClick}>{options[selectedIndex]}</Button>
                <Button
                    className='btn-split btn-split-right'
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}

