import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import LCContainer from "../_Base/LCContainer";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import LCSubtitle from "../../components/Typography/LCSubtitle";
import Apicall from "../../libs/Apicall";
import Paper from "@mui/material/Paper";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import CurrentUserContext from "../../context/CurrentUserContext";
import {Alert, Checkbox, InputLabel, Select, SvgIcon, TableHead} from "@mui/material";
import {currentUserInterface} from "../../libs/Auth";
import {currentMunicipalityInterface} from "../../App";
import {useParams} from "react-router-dom";
import Alerttypes from '../../libs/Alerttypes';
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import { SelectChangeEvent } from '@mui/material/Select';
import { ReactComponent as Edition } from '../../assets/images/icons/edit.svg';
import { ReactComponent as Delete } from '../../assets/images/icons/close.svg';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface propsInterface {
    municipality: currentMunicipalityInterface
}

interface userInterface {
    id: number;
    created_at: string;
    firstname: string;
    lastname: string;
    email: string;
    role: string;
    password?: string;
    alert_types: string[];
}
export default function Mymunicipality({municipality}:propsInterface) {
    const [users, setUsers] = useState<userInterface[]>([]);
    const currentuser = useContext(CurrentUserContext) as currentUserInterface;
    const [editingUser, setEditingUser] = useState<null | userInterface>(null);
    const [updateError, setUpdateError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState<null | userInterface>(null);
    const [error, setError] = useState<null|string>(null);
    const [newUser, setNewUser] = useState({
        firstname: "",
        lastname: "",
        email: "",
        role: "",
        alert_types: [] as string[]
    });
    const routeParams = useParams();

    const defaultUser = {
        firstname: "",
        lastname: "",
        email: "",
        role: "",
        alert_types: [] as string[],
    };

    const handleAddUser = () => {
        setNewUser(defaultUser);
        setError(null);
        setShowModal(true);
    }

    const handleCloseAddUSer = () => {
        setShowModal(false);
        setNewUser(defaultUser);
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setNewUser(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }

    const handleAddUserAPI = async () => {
        const apicall = new Apicall();
        apicall.call('POST', 'users/add', newUser).then((response) => {
            if(response.status === 'ERROR') {
                setError(String(response.error));
                return;
            }
            handleCloseAddUSer();

            // Refresh users
            apicall.call('GET','users').then((response) => {
                setUsers(response.data);
            })
        })
    }

    const handleUpdateUserAPI = async () => {
        if (!editingUser) return;

        const apicall = new Apicall();
        const response = await apicall.call('POST', `users/update`, editingUser);
        if(response.status === 'OK'){
            // Refresh users or update the user in the state
            setUsers(prev => prev.map(u => u.id === editingUser.id ? editingUser : u));

            setEditingUser(null);
            setUpdateError('');
        }else{
            setUpdateError(String(response.error));
        }

    }

    useEffect(() => {
        const apicall = new Apicall();
        apicall.call('GET','users').then((response) => {
            setUsers(response.data);
            if(routeParams.adminid) {
                const admintoedit = response.data.filter((adm:userInterface) => adm.id === parseInt(String(routeParams.adminid)));
                if(admintoedit.length){
                    setEditingUser(admintoedit[0]);
                }
            }
        })
    }, []);

    const handleEditBtn = (user:userInterface) => {
        setEditingUser(user);
    }

    const handleDeleteBtn = (user:userInterface) => {
        setUserToDelete(user);
        setShowDeleteModal(true);
    }

    const handleConfirmDelete = async () => {
        if (!userToDelete) return;

        const apicall = new Apicall();
        await apicall.call('POST', `users/delete`, {userid: userToDelete.id});

        // Refresh users or remove the user from the state
        setUsers(prev => prev.filter(u => u.id !== userToDelete.id));

        handleCloseDeleteModal();
    }

    const handleCloseDeleteModal = () => {
        setUserToDelete(null);
        setShowDeleteModal(false);
    }

    const handleAlertTypesChange = (event: SelectChangeEvent<string[]>) => {
        const selectedAlertTypes = event.target.value as string[];
        if (editingUser) {
            setEditingUser(prev => {
                if (prev) {
                    return { ...prev, alert_types: selectedAlertTypes } as userInterface;
                }
                return null;
            });
        } else {
            setNewUser(prev => ({ ...prev, alert_types: selectedAlertTypes }));
        }
    };

    return (
        <LCContainer title={'Utilisateurs'}>
            <Grid container sx={{px:3}}>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{borderRadius: 20, mt:3}}
                        onClick={handleAddUser}
                        className={'btn btn-blue btn-icon btn-icon-plus'}
                    >
                        Ajouter un utilisateur
                    </Button>
                </div>
            </Grid>
            <Grid item xs={12}>
                <React.Fragment>
                    <LCSubtitle>Utilisateurs</LCSubtitle>
                    <div  style={{width: '100%'}}>
                        <TableContainer component={Paper} className={' blocks'}>
                            <Table aria-label="simple table" className={'admin-table'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align={"center"} sx={{fontWeight: '700!important', fontSize:'14px', lineHeight:'20px'}}>Nom</TableCell>
                                        <TableCell align={"center"} sx={{fontWeight: '700!important', fontSize:'14px', lineHeight:'20px'}}>Email</TableCell>
                                        <TableCell align={"center"} sx={{fontWeight: '700!important', fontSize:'14px', lineHeight:'20px'}}>Rôle</TableCell>
                                        <TableCell align={"center"} sx={{fontWeight: '700!important', fontSize:'14px', lineHeight:'20px'}}>Date d'inscription</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.length > 0 ? (
                                        users.map((user) => (
                                            <TableRow
                                                key={user.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell className="name" align="left"><strong>{`${user.firstname} ${user.lastname}`}</strong></TableCell>
                                                <TableCell className="email" align="left">{user.email}</TableCell>
                                                <TableCell className="role" align="left">{user.role}</TableCell>
                                                <TableCell className="date" align="left">Créé le {moment(user.created_at).locale('fr').format('D MMMM YYYY')}</TableCell>

                                                <TableCell align="right" className="actions">
                                                    <IconButton aria-label="edit" color="primary" onClick={() => handleEditBtn(user)}>
                                                        <SvgIcon component={Edition} inheritViewBox />
                                                    </IconButton>
                                                    {currentuser.email !== user.email &&
                                                        <IconButton aria-label="delete" color="error" onClick={() => handleDeleteBtn(user)}>
                                                            <SvgIcon component={Delete} inheritViewBox />
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                Aucune alerte trouvée
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                </React.Fragment>
            </Grid>
            <Dialog open={showModal} onClose={handleCloseAddUSer} className='dialogBox' aria-labelledby="form-dialog-title">
                <DialogTitle variant={'h5'} id="form-dialog-title">Ajouter un utilisateur</DialogTitle>
                <DialogContent className={'dialogBox-content'}>
                    {
                        error &&
                        <Alert severity="error" variant={'filled'} sx={{my: 1}}>{error}</Alert>
                    }
                    <Box sx={{ mb:'1rem' }}>
                        <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:'0' }}>Prénom*</Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="firstname"
                            placeholder="Prénom"
                            // label="Prénom"
                            type="text"
                            fullWidth
                            value={newUser.firstname}
                            onChange={handleInputChange}
                        />
                    </Box>

                    <Box sx={{ mb:'1rem' }}>
                        <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:'0' }}>Nom*</Typography>
                        <TextField
                            margin="dense"
                            name="lastname"
                            placeholder="Nom"
                            // label="Nom"
                            type="text"
                            fullWidth
                            value={newUser.lastname}
                            onChange={handleInputChange}
                        />
                    </Box>

                    <Box sx={{ mb:'1rem' }}>
                        <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:'0' }}>Adresse courriel*</Typography>
                        <TextField
                            margin="dense"
                            name="email"
                            placeholder="Adresse courriel"
                            // label="Adresse courriel"
                            type="email"
                            fullWidth
                            value={newUser.email}
                            onChange={handleInputChange}
                        />
                    </Box>

                    <Box sx={{ mb:'1rem' }}>
                        <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:'0' }}>Rôle*</Typography>
                        <TextField
                            margin="dense"
                            name="role"
                            // label="Rôle"
                            placeholder="Rôle"
                            select
                            fullWidth
                            value={newUser.role}
                            onChange={handleInputChange}
                        >
                            <MenuItem value={"CITYADMIN"}>CITYADMIN</MenuItem>
                            <MenuItem value={"CITYREDACTOR"}>CITYREDACTOR</MenuItem>
                            <MenuItem value={"CITYADMINRESTRICTED"}>CITYADMINRESTRICTED</MenuItem>
                        </TextField>
                    </Box>
                    { (newUser.role === "CITYADMINRESTRICTED" || editingUser?.role === "CITYADMINRESTRICTED") && (
                        <FormControl fullWidth margin="dense" >
                            <InputLabel id="demo-multiple-name-label">Types d'alerte</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                multiple
                                name="alert_types"
                                value={newUser.alert_types || editingUser?.alert_types || []}
                                onChange={handleAlertTypesChange}
                                renderValue={(selected) => {
                                    const selectedKeys = selected as string[];
                                    const selectedNames = selectedKeys.map(key => {
                                        const matchingType = Alerttypes.find(alertType => alertType.key === key);
                                        return matchingType ? matchingType.name : null;
                                    }).filter(Boolean);
                                    return selectedNames.join(', ');
                                }}
                            >
                                {Alerttypes.map((alertType) => (
                                    <MenuItem key={alertType.key} value={alertType.key}>
                                        <Checkbox checked={newUser.alert_types?.includes(alertType.key) || editingUser?.alert_types?.includes(alertType.key) || false} />
                                        <ListItemText primary={alertType.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions sx={{padding:'20px 20px 30px', display:'flex', justifyContent:'space-between'}}>
                    <Button className={'btn btn-small btn-red'} onClick={handleCloseAddUSer} color="primary">Annuler</Button>
                    <Button className={'btn btn-small btn-blue'} onClick={handleAddUserAPI} color="primary">Ajouter</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showDeleteModal} onClose={handleCloseDeleteModal} aria-labelledby="confirm-delete-title">
                <DialogTitle id="confirm-delete-title">Confirmer la suppression</DialogTitle>
                <DialogContent>
                    Êtes-vous sûr de vouloir supprimer l'utilisateur <strong>{`${userToDelete?.firstname} ${userToDelete?.lastname}`}</strong>?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteModal} color="secondary">
                        Annuler
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={!!editingUser} onClose={() => setEditingUser(null)} aria-labelledby="edit-dialog-title">
                <DialogTitle id="edit-dialog-title">Modifier un utilisateur</DialogTitle>
                <DialogContent>
                    {
                        updateError &&
                        <Alert severity="error" variant={'filled'} sx={{my: 1}}>{updateError}</Alert>
                    }
                    <TextField
                        autoFocus
                        margin="dense"
                        name="firstname"
                        // label="Prénom"
                        placeholder="Prénom"
                        type="text"
                        fullWidth
                        value={editingUser?.firstname || ''}
                        onChange={e => setEditingUser(prev => {
                            if (prev) {
                                return { ...prev, firstname: e.target.value } as userInterface;
                            }
                            return null;
                        })}
                    />
                    <TextField
                        margin="dense"
                        name="lastname"
                        // label="Nom"
                        placeholder="Nom"
                        type="text"
                        fullWidth
                        value={editingUser?.lastname || ''}
                        onChange={e => setEditingUser(prev => {
                            if (prev) {
                                return { ...prev, lastname: e.target.value } as userInterface;
                            }
                            return null;
                        })}
                    />
                    <TextField
                        margin="dense"
                        name="email"
                        // label="Adresse courriel"
                        placeholder="Adresse courriel"
                        type="email"
                        fullWidth
                        value={editingUser?.email || ''}
                        onChange={e => setEditingUser(prev => {
                            if (prev) {
                                return { ...prev, email: e.target.value } as userInterface;
                            }
                            return null;
                        })}
                    />
                    <TextField
                        margin="dense"
                        name="password"
                        // label="Mot de passe (laisser vide pour ne pas changer)"
                        placeholder="Mot de passe (laisser vide pour ne pas changer)"
                        type="password"
                        onChange={e => setEditingUser(prev => {
                            if (prev) {
                                return { ...prev, password: e.target.value } as userInterface;
                            }
                            return null;
                        })}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="role"
                        // label="Rôle"
                        placeholder="Rôle"
                        select
                        fullWidth
                        value={editingUser?.role || ''}
                        onChange={e => setEditingUser(prev => {
                            if (prev) {
                                return { ...prev, role: e.target.value } as userInterface;
                            }
                            return null;
                        })}
                    >
                        <MenuItem value={"CITYADMIN"}>CITYADMIN</MenuItem>
                        <MenuItem value={"CITYREDACTOR"}>CITYREDACTOR</MenuItem>
                        <MenuItem value={"CITYADMINRESTRICTED"}>CITYADMINRESTRICTED</MenuItem>
                    </TextField>
                    { (newUser.role === "CITYADMINRESTRICTED" || editingUser?.role === "CITYADMINRESTRICTED") && (
                        <FormControl fullWidth margin="dense" >
                            <InputLabel id="demo-multiple-name-label">Types d'alerte</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                multiple
                                name="alert_types"
                                value={newUser.alert_types || editingUser?.alert_types || []}
                                onChange={handleAlertTypesChange}
                                renderValue={(selected) => {
                                    const selectedKeys = selected as string[];
                                    const selectedNames = selectedKeys.map(key => {
                                        const matchingType = Alerttypes.find(alertType => alertType.key === key);
                                        return matchingType ? matchingType.name : null;
                                    }).filter(Boolean);
                                    return selectedNames.join(', ');
                                }}
                            >
                                {Alerttypes.map((alertType) => (
                                    <MenuItem key={alertType.key} value={alertType.key}>
                                        <Checkbox checked={newUser.alert_types?.includes(alertType.key) || editingUser?.alert_types?.includes(alertType.key) || false} />
                                        <ListItemText primary={alertType.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditingUser(null)} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleUpdateUserAPI} color="primary">
                        Sauvegarder
                    </Button>
                </DialogActions>
            </Dialog>

        </LCContainer>
    )
}
