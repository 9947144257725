import Button from '@mui/material/Button';
import {LoginIcon} from './LCIcons';
import {Icon} from "@mui/material";
import {ButtonProps} from "@mui/material/Button";

const svgIcon = (
    <Icon sx={{width: 30, height:30}} className={'mr-2'}>
        <img alt="Connexion" src={LoginIcon} />
    </Icon>
);

export default function LCLoginBtn(props:ButtonProps) {
    return (
        <Button {...props} sx={{
                boxShadow: 5,
                "&:hover": {
                    backgroundColor:"#fcecc2"
                },
                "& .MuiButton-startIcon": {
                    margin: "0px"
                },
                background: '#FFB800',
                textAlign: 'center',
                borderRadius: '50px',
                height: 65,
                width: 65,
                ...props.sx
            }} startIcon={svgIcon} type={'submit'}  />
    );
}
