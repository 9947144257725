import React, {useEffect, useState} from "react";

interface propsInterface{
    children: string;
}
export default function LCDateformatter({children}: propsInterface) {
    const [formatted, setFormatted] = useState('');
    useEffect(() => {
        const options:Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }
        setFormatted(new Date(children).toLocaleDateString('fr-CA', options));
    },[children]);

    return (
        <React.Fragment>
            {formatted}
        </React.Fragment>
    );
}
