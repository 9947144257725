import React, {useEffect, useState} from "react";
import LCSubtitle from "../../components/Typography/LCSubtitle";
import LCPaper from "../../components/LCPaper";
import LCPager from "../../components/LCPager";
import Apicall from "../../libs/Apicall";
import LCDateformatter from "../../components/LCDateformatter";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

interface notesInterface {
    created_at: string;
    title: string;
    content: string;
}
export default function Releasenotes() {
    const [notes, setNotes] = useState<notesInterface[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const notemaxlength = 120;
    const [isLoaded, setIsLoaded] = useState(false);
    const [showFull, setShowFull] = useState(false);
    const validateAndSetCurrentPage = (offset:number) => {
        const newvalue = currentPage + offset;
        if(newvalue >= 1 && newvalue <= notes.length){
            setCurrentPage(newvalue);
            setShowFull(false);
        }
    }

    const excerpt = (content:string, showfull:boolean) => {
        if(showfull){
            return content;
        }
        if(content.length > notemaxlength){
            return content.slice(0,notemaxlength);
        }
        return content;
    }
    useEffect(() =>{
        const apicall = new Apicall();
        apicall.call('GET','release-notes').then((response) =>{
            if(response.status !== 'OK') {
                return;
            }
            setNotes(response.data);
            setIsLoaded(true);
        });
    }, [])
    return (
        <React.Fragment>
            <Box className={'realeasenotes'} >
                <LCSubtitle>Mises à jour Lien Citoyen</LCSubtitle>
                <LCPaper sx={{minHeight: '255px'}}>
                    {
                        notes.length > 0 &&
                        <React.Fragment>
                            <Box component={'div'} sx={{p:2}}>
                                <Typography sx={{color: '#FFB800', fontSize:'14px', fontFamily: 'Nunito Sans', fontWeight: 800}}>
                                    <LCDateformatter>{notes[currentPage-1].created_at}</LCDateformatter>
                                </Typography>
                                <Typography sx={{color: '#00004F', fontSize:'18px', fontFamily: 'Nunito Sans', fontWeight: 800}}>
                                    {notes[currentPage-1].title}
                                </Typography>
                                <Typography variant={'body1'} sx={{color: '#00004F', fontSize:'14px', fontFamily: 'Nunito Sans', fontWeight: 400}}>
                                    {excerpt(notes[currentPage-1].content, showFull)}
                                </Typography>
                                {
                                    notes[currentPage-1].content.length > notemaxlength && !showFull &&
                                    <Box sx={{display: 'flex', justifyContent: 'flex-end',alignItems:"flex-end"}}>
                                        <Button onClick={() => {setShowFull(true)}}> Lire plus...</Button>
                                    </Box>
                                }
                            </Box>
                        </React.Fragment>
                    }
                    { notes.length > 1 &&
                        <LCPager nbpages={notes.length} currentpage={currentPage} onChange={(direction) => {validateAndSetCurrentPage(direction)}} />
                    }
                    {
                        notes.length === 0 && isLoaded &&
                        <Typography sx={{color: '#00004F', fontSize:'1.1em', fontFamily: 'Nunito Sans', fontWeight: 800, padding:'25% 2rem' }}>Aucune nouvelle mise à jour pour l'instant</Typography>
                    }
                </LCPaper>
            </Box>
        </React.Fragment>
    )
}
