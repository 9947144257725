import React from 'react';
import LCContainer from "../_Base/LCContainer";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import 'moment/locale/fr';
import {useNavigate} from "react-router-dom";
import LCAlertsTable from "../../components/LCAlertsTable";
import {currentMunicipalityInterface} from "../../App";
import LCPaper from "../../components/LCPaper";
import Typography from "@mui/material/Typography";

interface propsInterface {
    municipality: currentMunicipalityInterface
}
export default function Alertlist({municipality}: propsInterface) {

    const navigate = useNavigate();

    const handleAddAlert = () => {
        navigate('/nouvelle-alerte');
    };

    return (
        <LCContainer title={'Alertes en cours'}>
            <Grid container sx={{px:3, pb: 3}}>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{borderRadius: 20, my:3}}
                        onClick={handleAddAlert}
                        className={'btn btn-blue btn-icon btn-icon-plus'}
                    >
                        Ajouter une alerte
                    </Button>
                </div>
            </Grid>

            <Grid container  sx={{px:3, pb: 3, flexDirection:'column'}}>
                <Typography variant={"h6"} color={'#00004F'} sx={{ mb:2 }}>Alertes en cours</Typography>

                <LCPaper sx={{minHeight: '240px'}}>
                    <LCAlertsTable />
                </LCPaper>

            </Grid>



        </LCContainer>
    )
}
