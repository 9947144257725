import * as React from "react";
import Box from "@mui/material/Box";
import Deco from "../../assets/images/laptop-deco.png";
import { motion } from 'framer-motion';

export default function Prefooter () {
    return (
        <Box className={'prefooter'}>
            <motion.div className='prefooter--left'
                        initial={{ opacity: 0, transform: 'translateX(-100px)' }}
                        whileInView={{ opacity: 1, transform: 'translateX(0px)' }}
                        transition={{ ease: "easeOut", duration: 0.5 }}
                        viewport={{ once: true }}>
                <img src={Deco} alt=""/>
            </motion.div>

            <motion.div className='prefooter--right'
                        initial={{ opacity: 0, transform: 'translateY(100px)' }}
                        whileInView={{ opacity: 1, transform: 'translateY(0px)' }}
                        transition={{ ease: "easeOut", duration: 0.5 }}
                        viewport={{ once: true }}>
                <h3 className='section-title'>Prêt à vous lancer ?</h3>
                <p className='section-paragraph'>Contactez-nous dès maintenant pour en savoir plus sur Lien Citoyen
                    et découvrir comment notre solution peut améliorer la communication
                    au sein de votre municipalité. Nous sommes prêts à vous accompagner
                    vers un avenir où l'engagement citoyen et la transparence sont au cœur
                    de votre administration. Réservez une démonstration gratuite et
                    découvrez les avantages de Lien Citoyen pour votre communauté.</p>
                <button className={'btn btn-small btn-yellow btn-shadow'} onClick={() => window.location.href = 'mailto:liencitoyen@cognitif.ca' }>Prenez rendez-vous</button>
            </motion.div>
        </Box>

    );
}
