import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import {Alert} from "@mui/material";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LCLoginBtn from "../../components/LCLoginBtn";
import LCInputText from "../../components/LCInputText";
import Apicall from "../../libs/Apicall";
import {currentMunicipalityInterface} from "../../App";

interface propsInterface {
    municipality: currentMunicipalityInterface
}

interface resetPasswordDetails {
    password: string;
    password_confirmation: string;
}

export default function Resetpassword({municipality}: propsInterface) {
    const navigate = useNavigate();
    const [tokenIsValid, setTokenIsValid] = useState(false);
    const location = useLocation();
    const [error, setError] = useState('');
    const [details, setDetails] = useState<resetPasswordDetails>({password: '', password_confirmation: ''});

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        setError('');
        event.preventDefault();

        // Validation du mot de passe
        if (details.password !== details.password_confirmation) {
            setError('Les mots de passe ne correspondent pas.');
            return;
        }
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        const apicall = new Apicall();
        apicall.call('POST', 'auth/new-password', {token, details}).then((response) => {
            if(response.status === 'OK') {
                localStorage.setItem('token', response.data);
                navigate('/dashboard');
            }else{
                setError(String(response.error));
            }
        });

    }

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        if (token) {
            const apicall = new Apicall();
            apicall.call('POST', 'auth/validate-token', { token, city_id: municipality.id }).then((response) => {
                if (response.status === 'OK') {
                    setTokenIsValid(true);
                } else {
                    setError(String(response.error));
                }
            });
        } else {
            setError("Aucun token fourni.");
        }
    }, [location]);

    return (
        <Grid container component="main" sx={{height: '100vh'}}>
            <CssBaseline/>
            <Grid
                item
                xs={false}
                sm={4}
                md={6}
                sx={{
                    background: 'url(images/' + municipality.splash_background + ') rgba(0, 0, 79, 0.9)',
                    backgroundBlendMode: 'multiply',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: 'center',
                }}
            >
                <Box
                    className={'classes.card'}
                    component="img"
                    sx={{
                        maxWidth: "40%",
                        display: "flex",
                        alignItems: "center"
                    }}
                    src={'images/' + municipality.logo}
                />
            </Grid>
            <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square sx={{backgroundColor: '#EFF2F3'}}>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box component="img" src={'images/logo.png'} sx={{width: '35%', mb: 5, mt: 10}}/>
                    {
                        error &&
                        <Alert severity="error" variant={'filled'} sx={{my: 1}}>{error}</Alert>
                    }
                    {
                        tokenIsValid &&
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
                            <LCInputText
                                margin="normal"
                                required
                                fullWidth
                                placeholder="Nouveau mot de passe"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDetails({
                                    ...details,
                                    password: e.target.value
                                })}
                            />
                            <LCInputText
                                margin="normal"
                                required
                                fullWidth
                                placeholder="Confirmez le nouveau mot de passe"
                                type="password"
                                id="confirmPassword"
                                autoComplete="new-password"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDetails({
                                    ...details,
                                    password_confirmation: e.target.value
                                })}
                            />
                            <Grid sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                my: 2,
                            }}>
                                <LCLoginBtn/>
                            </Grid>
                        </Box>
                    }
                </Box>
            </Grid>
        </Grid>
    );
}
