import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import {SvgIcon} from "@mui/material";
import { ReactComponent as Burger } from '../../assets/images/svg/icon-menu.svg';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const drawerWidth = 225;

interface Props {
    children: React.ReactNode,
    activeStepIdx: number;
}

export default function SubscriptionsDrawer({ children, activeStepIdx }:Props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // const [activeStep, setActiveStep] = useState(0);
    const steps = ['Informations de base', 'Adresse', 'Préférences d\'alerte', 'Sécurité', 'Confirmez votre position'];

    const drawer = (
        <div className={'subscription-drawer'}>
            <Toolbar className='side-navigation--header' sx={{ padding:'0', position:'relative' }}>
                <Box component="img" src={'/images/logo_dashboard.png'} sx={{ px: 3.125, pb: 3, pr: 3, pl: 3, width:'100%', height:'100%', objectFit: 'cover', }} />
            </Toolbar>

            <Stepper className='step-container' activeStep={activeStepIdx} orientation={window.innerWidth <= 600 ? "vertical" : "horizontal"}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );

    return (
        <Box id={'subscription-page'} sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed"
                sx={{
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    display: { xs:'block', md: 'none'},
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { xs: 'block', md:'none' } }}
                    >
                        <SvgIcon component={Burger} inheritViewBox />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box className={'subscriptionDrawer'} component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer variant="temporary" open={mobileOpen} onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#00004F', color: '#fff' },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer variant="permanent"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#00004F', color: '#fff' },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
                {children}
            </Box>
        </Box>
    );
}
