import React, {useEffect, useState} from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogContentText,
    DialogActions, Button, Typography
} from "@mui/material";
import LCInputText from "./LCInputText";
import { segmentsCounter } from "../libs/Twilio";
import Apicall from "../libs/Apicall";
import Alert from "@mui/material/Alert";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    smsContent?: string; // Nouveau prop facultatif
}

export default function SendTestSMSModal({ isOpen, onClose, smsContent }: Props) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [localSmsContent, setLocalSmsContent] = useState('');
    const [smsMessageCount, setSmsMessageCount] = useState(1);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState<null|string>(null);

    const handleSmsContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const messageCount = segmentsCounter(e.target.value);
        setSmsMessageCount(messageCount);
        setLocalSmsContent(e.target.value);
    };

    const handleSendClick = () => {
        //send msg
        const apicall = new Apicall();
        setError(null);
        setIsSuccess(false);
        apicall.call('POST', 'alerts/test-sms', {sms_content: smsContent, phone: phoneNumber}).then((response) => {
            if(response.status === 'OK'){
                setIsSuccess(true);
            }else{
                setError(response.error || 'Erreur serveur');
            }
        });
    };

    useEffect(() => {
        setIsSuccess(false);
        setError(null);
    }, [isOpen]);

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Entrer un numéro de téléphone</DialogTitle>
            {isSuccess &&
                <Alert severity="success">Le test SMS a été envoyé...</Alert>
            }
            {error &&
                <Alert severity="error">{error}</Alert>
            }
            <DialogContent>
                <DialogContentText>
                    Veuillez entrer le numéro de téléphone pour envoyer un test SMS.
                </DialogContentText>
                <LCInputText
                    fullWidth
                    value={phoneNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
                    placeholder="Numéro de téléphone"
                />

                {!smsContent && (
                    <>
                        <LCInputText
                            margin="normal"
                            required
                            fullWidth
                            placeholder={"Contenu du message SMS"}
                            value={localSmsContent}
                            onChange={handleSmsContentChange}
                        />
                        <Typography variant="caption">
                            Nbre de segments: {smsMessageCount}
                        </Typography>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Fermer
                </Button>
                <Button onClick={handleSendClick} color="primary" disabled={phoneNumber.length !== 10}>
                    Envoyer Test
                </Button>
            </DialogActions>
        </Dialog>
    );
}
