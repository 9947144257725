import * as React from "react";
import Box from "@mui/material/Box";
import {Container, SvgIcon} from "@mui/material";
import './icons.scss';
import { ReactComponent as Facebook } from '../../assets/images/icons/facebook.svg';


type Props = {
    icon:string;
    title: string,
    date: string,
    message: string,
    link: string,
}

function generateFacebookShareLink(url: string) {
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
}

const facebookShareLink = generateFacebookShareLink(window.location.href);

export default function AlertDetailled({ icon,title,date,message,link }: Props) {
    return(
        <Container sx={{ maxWidth:{xl:'1680px'} }}>
            <Box className='alert-details--header'>
                <Box className='alert-details--header__icon' sx={{ mr: '1rem',  }}>
                    <div dangerouslySetInnerHTML={{__html: icon}} className={'icon'} />
                </Box>
                <Box className='alert-details--header__content'>
                    <h4 className='title'>{title}</h4>
                </Box>
            </Box>
            <Box className='alert-details--content'>
                <h4 className='date'>{date}</h4>
                <p className='message'>{message}</p>
                <a href={facebookShareLink} target="_blank" className='share-button share-button' rel="noopener noreferrer" style={{ marginBottom: '36px' }}> <SvgIcon className='share share-shadow' component={Facebook} inheritViewBox /> Partager sur Facebook</a>
                <a href={link} className='btn btn-yellow btn-small btn-shadow alert-link'>Retour</a>
            </Box>
        </Container>
    )
}
