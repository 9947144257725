import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import {Alert} from "@mui/material";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LCLoginBtn from "../../components/LCLoginBtn";
import LCInputText from "../../components/LCInputText";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Auth from "../../libs/Auth";
import {currentMunicipalityInterface} from "../../App";
import Apicall from "../../libs/Apicall";

interface propsInterface {
    municipality: currentMunicipalityInterface
}

interface detailsInterface {
    email: string;
    password: string;
}
export default function Login({municipality}: propsInterface) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [error,setError] = useState('');
    const [details, setDetails] = useState({} as detailsInterface);
    const [recoveryEmail, setRecoveryEmail] = useState('');
    const [recoveryErr, setRecoveryErr] = useState('');
    const [resetMessage, setResetMessage] = useState('');
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>):void => {
        setError('');
        event.preventDefault();
        const auth = new Auth();
        auth.login(details.email, details.password, municipality.id).then((response) => {
            if(!response){
                setError(auth.getLastError().errormessage);
                return;
            }
            navigate('/dashboard');
        }).catch((e:any) =>{
            setError(e.message);
        })
    }

    const checkuser = async () => {
        const user = false;
        if(user){
            navigate('/dashboard');
        }
    }
    useEffect(() => {
        checkuser();
    });

    const handleResetPassword = async () => {
            const apicall = new Apicall();
            apicall.call('POST','auth/lost-password', {email: recoveryEmail, city_id: municipality.id}).then((response) => {
                if(response.status === 'OK'){
                    setResetMessage('Un lien de réinitialisation a été envoyé à votre adresse e-mail.');
                    setTimeout(() => {
                        setRecoveryErr('');
                        setResetMessage('');
                        handleClose();
                    }, 2000); // Fermez le dialogue après 2 secondes
                }else{
                    setRecoveryErr(String(response.error));
                }
            });
    };

    return (
        <Grid container component="main" className='login-page' sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={12}
                md={7}
                sx={{
                    background: 'url(images/'+municipality.splash_background+') rgba(0, 0, 79, 0.9)',
                    backgroundBlendMode: 'multiply',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: "flex",
                    flexDirection:'column',
                    justifyContent: "center",
                    alignItems: 'center',
                }}
            >
                <Box
                    className={'classes.card'}
                    component="img"
                    sx={{
                        maxWidth: "40%",
                        display: "flex",
                        alignItems: "center"
                    }}
                    src={'images/'+municipality.logo}
                />

                <Box className='account-creation'>
                    <p>Pas encore de compte ? Inscrivez-vous, c'est gratuit!</p>
                    <a href={'/s-enregistrer'} className={'btn btn-white btn-icon btn-icon-pen'}>Je m'inscris gratuitement</a>
                </Box>

            </Grid>
            <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square sx={{backgroundColor: '#EFF2F3'}}>
                <Box
                    sx={{
                        mx: 2,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent:'center'
                    }}
                >
                    <Box component="img" src={'images/logo.png'} sx={{width: '35%', mb: 5, mt: 10}} />
                    {
                        error &&
                        <Alert severity="error" variant={'filled'} sx={{my: 1}}>{error}</Alert>
                    }
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <LCInputText
                            margin="normal"
                            required
                            fullWidth
                            placeholder={"Addresse courriel"}
                            id="email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={(e:React.ChangeEvent<HTMLInputElement>) => setDetails({...details, email: e.target.value})}
                        />
                        <LCInputText
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            placeholder="Mot de passe"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(e:React.ChangeEvent<HTMLInputElement>) => setDetails({...details, password: e.target.value})}
                        />
                        <Grid sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            my: 2,
                        }}>
                            <Button className="underline-btn" onClick={handleOpen} sx={{mb:3, mt:0}}>J'ai oublié mon mot de passe</Button>
                            <LCLoginBtn/>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Récupération du mot de passe</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Veuillez entrer votre adresse e-mail pour recevoir un lien de récupération de mot de passe.
                    </DialogContentText>
                    {
                        recoveryErr &&
                        <Alert severity="error" variant={'filled'} sx={{my: 1}}>{recoveryErr}</Alert>
                    }
                    {
                        resetMessage &&
                        <Alert severity="info" variant={'filled'} sx={{my: 1}}>{resetMessage}</Alert>
                    }
                    <TextField
                        autoFocus
                        margin="dense"
                        placeholder="Adresse courriel"
                        type="email"
                        fullWidth
                        value={recoveryEmail}
                        onChange={(e) => setRecoveryEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Annuler</Button>
                    <Button onClick={() => handleResetPassword()} color="primary">Envoyer</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
