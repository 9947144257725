import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import moment from "moment/moment";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import React, {useContext, useEffect, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Apicall from "../libs/Apicall";
import Alerttypes from "../libs/Alerttypes";
import {LCGenericIcon} from "./LCIcons";
import {useNavigate} from "react-router-dom";
import CurrentUserContext from "../context/CurrentUserContext";
import { ReactComponent as Edition } from '../assets/images/icons/edit.svg';
import { ReactComponent as Delete } from '../assets/images/icons/close.svg';

import {SvgIcon} from "@mui/material";

interface userInterface {
    id: number;
    created_at: string;
    firstname: string;
    lastname: string;
    email: string;
    role: string;
    hasCitizen?: boolean;
    alertTypes: string[];
}

export default function LCAlertsTable() {

    const [alerts, setAlerts] = useState([] as any[]);
    const [open, setOpen] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(null as any);
    const [detailOpen, setDetailOpen] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState(null as any);

    const statusTranslations: { [key: string]: string } = {
        'DRAFT': 'BROUILLON',
        'SENDING': 'ENVOI EN COURS',
        'SEND_DONE': 'ENVOYÉ'
    };

    const translateStatus = (status: string) => {
        return statusTranslations[status] || status;
    };

    const navigate = useNavigate();


    const handleEditBtn = (alert: any) => {
        navigate(`/alertes/modifier/${alert.id}`);
    }
    const handleOpen = (alert: any) => {
        setDeleteAlert(alert);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDetailOpen = (alert: any) => {
        setSelectedAlert(alert);
        setDetailOpen(true);
    };

    const handleDetailClose = () => {
        setDetailOpen(false);
        const apicall = new Apicall();
        apicall.call('GET', 'alerts').then((response) => {
            setAlerts(response.data);
        })
    };

    const handleDelete = () => {
        const apicall = new Apicall();
        apicall.call('POST', `alerts/delete`, {id: deleteAlert.id}).then(() => {
            // re-fetch alerts after deletion
            apicall.call('GET', 'alerts').then((response) => {
                setAlerts(response.data);
            })
        });
        setOpen(false);
    };
    useEffect(() => {
        const apicall = new Apicall();
        apicall.call('GET', 'alerts').then((response) => {
            setAlerts(response.data);
        })
    },[]);

    const getAlertIcon = (type: string) => {
        const alertType = Alerttypes.find((alert:any) => alert.key === type);
        return alertType ?  <LCGenericIcon height={30} width={30} iconsrc={alertType.icon}/> : null;
    }

    const getAlertName = (type: string):string => {
        const alertType = Alerttypes.find((alert:any) => alert.key === type);
        return alertType ? alertType.name : type;
    }

    return (
        <React.Fragment>
            <TableContainer component={Paper} className={'table-alert blocks'}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody className='alert-progress'>
                        {alerts.length > 0 ? (
                            alerts.map((alert) => (
                                <TableRow
                                    key={alert.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    className='alert-line'
                                >
                                    <TableCell component="th" scope="row" className={'alert-line--icon'}> {getAlertIcon(alert.type)} </TableCell>
                                    <TableCell align="left" className={'alert-line--type'}><strong>{getAlertName(alert.type)}</strong></TableCell>
                                    {/*<TableCell align="left" className={'alert-line--titre'}>{alert.title}</TableCell>*/}
                                    <TableCell align="left" className={'alert-line--start'}>Alerte émise le {moment(alert.start_date).locale('fr').format('D MMMM YYYY')}</TableCell>
                                    <TableCell align="left" className={'alert-line--end'}>Levée prévue le {alert.end_date ? moment(alert.end_date).locale('fr').format('D MMMM YYYY') : 'Indéfini'}</TableCell>
                                    <TableCell align="left" className={'alert-line--warnings'}>
                                        {moment().isBefore(moment(alert.end_date)) ?
                                            <Chip label="En vigueur" color="warning" size="small" className="warnings" /> :
                                            <Chip label="Avis levé" color="success" size="small" className="success"/>
                                        }
                                    </TableCell>
                                    <TableCell align="left" className={'alert-line--status'}>
                                        <Button variant={'outlined'} size={'small'} className={alert.status.toLowerCase()} onClick={() => handleDetailOpen(alert)}> {translateStatus(alert.status)} </Button>
                                    </TableCell>
                                    <TableCell align="left" className={'alert-line--options'}>
                                        <IconButton aria-label="edit" color="primary" onClick={() => handleEditBtn(alert)}>
                                            <SvgIcon component={Edition} inheritViewBox />
                                        </IconButton>
                                        <IconButton aria-label="delete" color="error" onClick={() => handleOpen(alert)}>
                                            <SvgIcon component={Delete} inheritViewBox />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    Aucune alerte trouvée
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmer la suppression"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Êtes-vous sûr de vouloir supprimer cette alerte? Cette action est irréversible.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
            {
                selectedAlert &&
                <AlertDetailDialog alert={selectedAlert} open={detailOpen} onClose={handleDetailClose} />
            }
        </React.Fragment>
    );
}

interface AlertDetailProps {
    alert: any;
    open: boolean;
    onClose: () => void;
}

const AlertDetailDialog: React.FC<AlertDetailProps> = ({ alert, open, onClose }) => {
    const [channelStats, setChannelStats] = useState<{ [key: string]: number }>({
        notif_sms: 0,
        notif_vocal_msg: 0,
        notif_email: 0
    });
    const [distinctCitizens, setDistinctCitizens] = useState(0);
    const channels = [
        { name: 'Message texte', key: 'notif_sms' },
        { name: 'Appel téléphonique', key: 'notif_vocal_msg' },
        { name: 'Courriel', key: 'notif_email' },
    ];

    const currentuser:userInterface = useContext(CurrentUserContext) as userInterface;

    const statusTranslations: { [key: string]: string } = {
        'DRAFT': 'BROUILLON',
        'SENDING': 'ENVOI EN COURS',
        'SEND_DONE': 'ENVOYÉ'
    };

    const navigate = useNavigate();

    const translateStatus = (status: string) => {
        return statusTranslations[status] || status;
    };

    const startSending = async() => {
        const apicall = new Apicall();
        return apicall.call('POST', 'alerts/start-sending', {id: alert.id}).then(() => {
            onClose();
            navigate('/alertes');
        });
    }

    useEffect(() => {
        const apicall = new Apicall();
        apicall.call('POST', 'alerts/get-sending-stats', {id: alert.id}).then((response) => {
            if (response.status === 'OK' && response.data) {
                setChannelStats(response.data.channels);
                setDistinctCitizens(response.data.totaldistinct);
            }
        })
   },[]);

     return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>{"Détails de l'envoi de l'alerte"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <strong>Alerte :</strong> {alert.title}
                </DialogContentText>
                <DialogContentText>
                    <strong>Status :</strong> {translateStatus(alert.status)}
                </DialogContentText>
                <DialogContentText>
                    <strong>Nombre de citoyens distincts :</strong> {distinctCitizens}
                </DialogContentText>
                <DialogContentText>
                    <strong>Canaux utilisés :</strong>
                </DialogContentText>
                <ul>
                    {channels.map(channel => (
                        <li key={channel.name}>
                            {channel.name}: {channelStats[channel.key]} citoyens
                        </li>
                    ))}
                </ul>
                {/* Vous pouvez ajouter plus d'informations ici selon les besoins */}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color={'error'}>
                    Fermer
                </Button>
                {(currentuser.role === 'CITYADMIN' || currentuser.role === 'CITYADMINRESTRICTED') && alert.status === 'DRAFT' && (
                    <Button color="success" variant={'contained'} onClick={() => startSending()}>
                        Démarrer l'envoi
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
