import React, {useContext} from 'react';
import CurrentUserContext from "../../context/CurrentUserContext";
import {currentUserInterface} from "../../libs/Auth";
import Homepage from "../Homepage/Homepage";
import {currentMunicipalityInterface} from "../../App";
import Dashboard from "./Dashboard";
interface propsInterface {
    municipality: currentMunicipalityInterface
}
export default function DashboardSelector({municipality}: propsInterface) {
    const currentuser = useContext(CurrentUserContext) as currentUserInterface;

    return (
        <React.Fragment>
            {
                currentuser.role === 'CITIZEN'
                &&

                <Homepage municipality={municipality} />
            }
            {
                ['CITYADMIN','CITYREDACTOR','CITYADMINRESTRICTED'].includes(currentuser.role)
                &&
                <Dashboard municipality={municipality} />
            }
        </React.Fragment>
    )
}
