import React, {useContext, useState} from 'react';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CampaignIcon from '@mui/icons-material/Campaign';
import MessageIcon from '@mui/icons-material/Message';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import CurrentUserContext from "../../context/CurrentUserContext";
import Auth, {currentUserInterface} from '../../libs/Auth';
import {useNavigate} from "react-router-dom";
import { Link } from 'react-router-dom';
import Diversity2RoundedIcon from '@mui/icons-material/Diversity2Rounded';
import {SvgIcon} from "@mui/material";
import { ReactComponent as Burger } from '../../assets/images/svg/icon-menu.svg';
import Footer from "../../components/Layout/Footer/Footer";
import MenuList from "@mui/material/MenuList";

type Props = {
    title: string,
    classSection?: string,
    children: React.ReactNode
}

const drawerWidth: number = 245;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const LMenuItem = ({text}: any) => {
    return (
        <ListItemText primary={text} sx={{color: '#EFF2F3', textTransform:'uppercase'}} primaryTypographyProps={{fontSize: '12px'}} />
    )
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export default function LCContainer({title, classSection, children}: Props) {
    const theme = useTheme();
    const ismobile = !useMediaQuery(theme.breakpoints.up('sm'));
    const [open, setOpen] = useState(!ismobile);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // const ropen = Boolean(anchorEl);
    const currentuser = useContext(CurrentUserContext) as currentUserInterface;
    const navigate = useNavigate();

    // const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        item: string,
    ) => {
        setAnchorEl(null);
        switch(item){
            case "logout":
                const auth = new Auth();
                auth.logout();
                navigate('/login');
                break;
            default:
                navigate(`/ma-ville/${currentuser.id}`);
                break;
        }
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };


    return (
        <Box className={`wrapper-dashboard ${classSection}`} sx={{ display: 'flex' }}>
            <AppBar position="absolute" open={open} elevation={0} sx={{color:'#000'}}>
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                        backgroundColor: '#EFF2F3',
                    }}
                    className={'toolbar'}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                        className={'burgerMenu'}
                    >
                        <SvgIcon component={Burger} inheritViewBox />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        <Box sx={{cursor: 'pointer', display: {xs:'none', sm:'inline-block'} }} onClick={toggleDrawer}>{title}</Box>

                    </Typography>
                    {/*{ !ismobile &&*/}
                    {/*    <Typography sx={{textDecoration:'underline', cursor:'pointer'}} onClick={handleClickListItem}>Bonjour {currentuser.firstname}!</Typography>*/}
                    {/*}*/}
                    {/*{ ismobile &&*/}
                    {/*    <IconButton color="inherit" onClick={handleClickListItem}>*/}
                    {/*        <AccountCircleIcon />*/}
                    {/*    </IconButton>*/}
                    {/*}*/}
                    {/*<Box >*/}
                        <MenuList className='toolbar-content'>
                            {
                                currentuser.role !== 'CITYADMINRESTRICTED' &&
                                <MenuItem onClick={(event:React.MouseEvent<HTMLElement>) => handleMenuItemClick(event, 'profile')} >
                                    <Typography  sx={{ fontSize:'14px' }}>Mon profil</Typography>
                                </MenuItem>
                            }
                            <MenuItem
                                sx={{color: 'error.main'}}
                                color={'error.main'}
                                onClick={(event:React.MouseEvent<HTMLElement>) => handleMenuItemClick(event, 'logout')}>
                                <Typography sx={{ fontSize:'14px' }}>Déconnexion</Typography>
                            </MenuItem>
                        </MenuList>
                    {/*</Box>*/}
                </Toolbar>
            </AppBar>

            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                        backgroundColor: '#00004F'
                    }}
                >
                    <IconButton onClick={toggleDrawer} sx={{color: '#fff'}}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Box component="img" src={'/images/logo_dashboard.png'} sx={{px: 6, pb: 3, backgroundColor: '#00004F'}} />
                <List className='dashboard-navigation' component="nav" sx={{backgroundColor: '#00004F', flexGrow: 1,
                    height: '90vh',
                    overflow: 'auto',}}>
                    <ListItemButton component={Link} to={'/dashboard'}>
                        <ListItemIcon>
                            <DashboardIcon sx={{color: '#EFF2F3'}} />
                        </ListItemIcon>
                        <LMenuItem text={'Tableau de bord'} />
                    </ListItemButton>
                    {
                        currentuser.role !== 'CITYADMINRESTRICTED' &&
                        <React.Fragment>
                            <ListItemButton component={Link} to={'/citoyens'}>
                                <ListItemIcon>
                                    <Diversity3Icon sx={{color: '#EFF2F3'}} />
                                </ListItemIcon>
                                <LMenuItem text={'Citoyens'} />
                            </ListItemButton>
                            <ListItemButton component={Link} to={'/ma-ville'}>
                                <ListItemIcon>
                                    <Diversity2RoundedIcon sx={{color: '#EFF2F3'}} />
                                </ListItemIcon>
                                <LMenuItem text={'Administrateurs'} />
                            </ListItemButton>
                        </React.Fragment>
                    }
                    <ListItemButton component={Link} to={'/alertes'}>
                        <ListItemIcon>
                            <CampaignIcon sx={{color: '#EFF2F3'}} />
                        </ListItemIcon>
                        <LMenuItem text={'Alertes aux citoyens'} />
                    </ListItemButton>
                    <ListItemButton component={Link} to={'/'}>
                        <ListItemIcon>
                            <LocationCityIcon sx={{color: '#EFF2F3'}} />
                        </ListItemIcon>
                        <LMenuItem text={'Page Publique'} />
                    </ListItemButton>
                    {
                        false &&
                        <ListItemButton>
                            <ListItemIcon>
                                <MessageIcon sx={{color: '#EFF2F3'}} />
                            </ListItemIcon>
                            <LMenuItem text={'communications'} />
                        </ListItemButton>
                    }
                </List>
            </Drawer>
            <Box
                component="main"
                className='main-content'
                sx={{
                    backgroundColor: '#EFF2F3',
                    flexGrow: 1,
                    overflow: 'auto',
                }}
            >
                <Box className={'wrapper'} sx={{
                    backgroundColor: '#EFF2F3',
                    flexGrow: 1,
                    overflow: 'auto',
                    // marginTop: {xs:'65px', md:'0'},
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Toolbar />
                    <Container maxWidth={false} sx={{ mt: 10, mb:'15%'}}>
                        <Grid container spacing={3}>
                            {children}
                        </Grid>
                    </Container>
                    <Footer />
                </Box>
            </Box>
        </Box>

    )
}
