import React, {useContext} from 'react';
import LCContainer from '../_Base/LCContainer';
import Grid from "@mui/material/Grid";
import Welcomepanel from './Welcomepanel';
import Quickactionspanel from "./Quickactionspanel";
import Releasenotes from "./Releasenotes";
import LCAlertsTable from "../../components/LCAlertsTable";
import LCSubtitle from "../../components/Typography/LCSubtitle";
import LCPaper from "../../components/LCPaper";
import {currentMunicipalityInterface} from "../../App";
import CurrentUserContext from "../../context/CurrentUserContext";
import { Alert, AlertTitle } from '@mui/material';
import Box from "@mui/material/Box";

interface propsInterface {
    municipality: currentMunicipalityInterface
}
interface userInterface {
    id: number;
    created_at: string;
    firstname: string;
    lastname: string;
    email: string;
    role: string;
    hasCitizen?: boolean;
    alertTypes: string[];
}

export default function Dashboard({municipality}: propsInterface) {
    const currentuser:userInterface = useContext(CurrentUserContext) as userInterface;
    return(
        <LCContainer title={'Bonjour ' + currentuser.firstname}>
            <Box className={'container'}>
                {!currentuser.hasCitizen &&
                    <Grid item xs={12} sx={{pb:3}}>
                        <Alert variant={'outlined'} severity={'info'}>
                            <AlertTitle>Attention, {currentuser.firstname} !</AlertTitle>
                            Nous avons remarqué que vous n'avez pas encore complété votre profil citoyen.<br /> Complétez votre profil dès maintenant pour recevoir également les alertes et rester informé des incidents en temps réel.
                            <br /><br />
                            <a href={`/s-enregistrer?firstname=${currentuser.firstname}&lastname=${currentuser.lastname}&email=${currentuser.email}`}>Complétez votre profil ici</a>
                        </Alert>
                    </Grid>
                }
                {
                    currentuser.role !== 'CITYADMINRESTRICTED' &&
                    <Box className={'first-row'}>
                        <Welcomepanel logo={municipality.logo2} />
                        <Releasenotes />
                        <Quickactionspanel />
                    </Box>
                }

                <Grid item xs={12}>
                    <React.Fragment>
                        <LCSubtitle>Alertes en cours</LCSubtitle>
                        <LCPaper sx={{minHeight: '240px'}}>
                            <LCAlertsTable />
                        </LCPaper>
                    </React.Fragment>
                </Grid>
            </Box>
        </LCContainer>
    )
}
