import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import React from "react";

interface BigButtonPropsInterface {
    icon: any;
    text: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export default function LCBigSquareBtn({icon, text, onClick}: BigButtonPropsInterface) {
    return (
        <Button className='btn-square btn-square-icon' onClick={onClick} >
            <Box>
                {icon}
                <p>{text}</p>
            </Box>
        </Button>
    );
}
