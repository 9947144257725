import * as React from "react";
import Box from "@mui/material/Box";
import HeaderImage from "../../assets/images/iphone-main_xl.png";
import { motion } from "framer-motion";

// type Props = {
//     title:string;
// }

export default function HeroBlock () {
    return (

        <Box className="hero-block" >
            <Box className="hero-block--left">
                <img className='hero-image' alt='Ville' src={ HeaderImage } />
            </Box>
            <Box className="hero-block--right">
                <motion.div className='hero-block--content'
                            initial={{ opacity: 0, transform: 'translateY(100px)' }}
                            whileInView={{ opacity: 1, transform: 'translateY(0px)' }}
                            transition={{ ease: "easeOut", duration: 0.5 }}
                            viewport={{ once: true }}
                >
                    <h1>Pour une vie de citoyen connecté!</h1>
                    <p>Créez un lien puissant entre votre municipalité et ses citoyens grâce au système d'alertes municipales de Lien Citoyen. Préparez-vous à une communication fluide et interactive avec vos résidents grâce à nos prochaines fonctionnalités. Tout cela, simplifié par une plateforme conviviale à la pointe de la technologie.</p>
                    <button className={'btn btn-blue btn-shadow'} onClick={() => window.location.href = 'mailto:liencitoyen@cognitif.ca'}>Connecter une municipalité</button>
                </motion.div>
            </Box>
        </Box>
    );
}
