import * as React from "react";
import Ordinateur from "../../assets/images/macbook-air.png";
import { motion } from "framer-motion";


export default function BlockCenter () {
    return (

        <motion.div className={'block-center'}
                    initial={{ opacity: 0, transform: 'translateY(100px)' }}
                    whileInView={{ opacity: 1, transform: 'translateY(0px)' }}
                    transition={{ ease: "easeOut", duration: 0.3 }}
                    viewport={{ once: true }}
        >
            <img className='block-image' src={ Ordinateur } alt={'background'}/>
        </motion.div>
    );
}
