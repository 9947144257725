import axios from 'axios';
import Apicall from "./Apicall";
import * as env from '../env';
export interface currentUserInterface {
    firstname: string;
    lastname: string;
    email: string;
    role: string;
    id: number;
    hasCitizen?: boolean;
    alertTypes: string[];
}
export const getProfile = ():Promise<currentUserInterface|null> => {
    return new Promise(async(res) => {
        const apicall = new Apicall();
        apicall.call('GET','auth/ping').then((pingresponse) => {
            if(pingresponse.status !== "OK"){
                res(null);
                return;
            }
            res(pingresponse.data.user);
        }).catch(() =>{
            res(null);
        });
    });
}

interface errorInterface {
    errorcode:string;
    errormessage:string;
}
class Auth {
    errorcode:string = '';
    errormessage:string = '';

    public async login(email:string, password: string, city_id: number):Promise<boolean> {
        this.clearErrMsg();
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.defaults.headers.common['Accept-Language'] = 'fr';
        return axios.post(env.apiUrl + `/api/auth/login`, {email, password, city_id}).then((response) => {
            if(response.data.status === 'ERROR') {
                this.errorcode = 'ERR000';
                this.errormessage = response.data.message;
                localStorage.removeItem('token');
                return false;
            }
            localStorage.setItem('token', response.data);
            return true;
        }).catch((err:any) => {
            localStorage.removeItem('token');
            this.errorcode = 'ERR000';
            this.errormessage = err.response.data.message
            return false;
        });

    }

    public getLastError():errorInterface {
        return {errorcode: this.errorcode, errormessage: this.errormessage}
    }

    private clearErrMsg():void {
        this.errorcode = '';
        this.errormessage = '';
    }

    public async logout():Promise<boolean> {
        localStorage.removeItem('token');
        return true;
    }
}

export default Auth;
