import React from "react";
import {LCLeftarrowIcon, LCRightarrowIcon} from "./LCIcons";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import LCSubtitle from "./Typography/LCSubtitle";
interface propsInterface {
    currentpage: number;
    nbpages: number;
    onChange: (dir:number) => void;
}
export default function LCPager(props:propsInterface){

    return(
            <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                    <Button variant={'text'} size="small" startIcon={<LCLeftarrowIcon width={25} height={20} />} onClick={() => {props.onChange(-1)}} />
                </Grid>
                <Grid item sx={{mt: 0.3}}>
                    <LCSubtitle>{props.currentpage} / {props.nbpages}</LCSubtitle>
                </Grid>
                <Grid item>
                    <Button variant={'text'} size="small" startIcon={<LCRightarrowIcon width={25} height={20} />} onClick={() => {props.onChange(1)}} />
                </Grid>
            </Grid>
    )
}
