import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";
import {CitizenFormData} from "../Citizenonboarding";

interface propsInterface {
    formData: CitizenFormData;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export default function PasswordForm({formData, handleInputChange}: propsInterface) {
    return (
        <React.Fragment>
            <Typography variant={'h3'} sx={{color: '#FFB800', fontSize:'24px', fontFamily: 'Nunito Sans', fontWeight: 800, px:3, pb: 3, pl: 0, mb:'2rem'}}>Créer un mot de passe</Typography>
            {/*<Typography variant={'h3'} sx={{color: '#FFB800', fontSize:'24px', fontFamily: 'Nunito Sans', fontWeight: 800, px:3, pb: 1, pl: 0,}}>Sécurité</Typography>*/}
            <Grid container spacing={{md:2}}>
                <Grid item xs={12} md={6} sx={{marginBottom:'2rem',}}>
                    <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:'10px' }}>Mot de passe*</Typography>
                    <TextField placeholder="Mot de passe*" fullWidth type="password" /*label="Mot de passe"*/ variant="outlined" value={formData.password} name="password" onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography sx={{color: '#00004F', fontSize:'16px', fontFamily: 'Nunito Sans', fontWeight: 700, lineHeight:'20px', pb:'10px' }}>Confirmez le mot de passe*</Typography>
                    <TextField placeholder="Confirmez le mot de passe" fullWidth type="password" /*label="Confirmez le mot de passe"*/ variant="outlined" value={formData.confirmPassword} name="confirmPassword" onChange={handleInputChange} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
