import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

interface PropsInterface {
    onClose: (area?: CoordsInterface[]) => void;
    loadedSection: CoordsInterface[];
    default_lat: number;
    default_lng: number;
}

interface CoordsInterface {
    lat: number;
    lng: number;
}

export default function LCSectorSelector(props:PropsInterface) {

    //TODO: restreindre la clef api
    const GOOGLE_MAPS_APIKEY = 'AIzaSyCySZqb8qWynQiUpVslZvwyd8-9KhO8ny8';
    const defaults = {
        z: 15,
        lat: props.default_lat,
        lng: props.default_lng,
    };

    const [error, setError] = useState('');
    const [map, setMap] = React.useState<google.maps.Map | null>(null);
    const [polyline, setPolyline] = useState<google.maps.Polyline | null>(null);
    const [terminationMarquer, setTerminationMarquer] = useState<CoordsInterface | null>(null)
    const containerStyle = {
        width: '100%',
        height: '100%'
    };
    const [center] = useState({
        lat: defaults.lat,
        lng: defaults.lng
    })
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAPS_APIKEY
    })
    const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
        setMap(null)
    }, []);

    const onLoad = React.useCallback(function callback(map: google.maps.Map) {
        map.setZoom(15);
        map.setOptions({
            draggableCursor: 'default',
            clickableIcons: false,
            fullscreenControl: false,
            streetViewControl: false,
        })
        setMap(map);
    }, [])

    useEffect(() => {
        if(isLoaded && map && props.loadedSection.length){
            setCanAddAnotherPoint(false);
            let tmp = props.loadedSection;
            setTerminationMarquer(tmp[0]);
            setPathCoordinates(tmp);
            const tmppl = new google.maps.Polyline();
            tmppl.setPath(tmp);
            tmppl.setMap(map);
            setPolyline(tmppl);
        }

    }, [isLoaded, map, props.loadedSection]);

    const [pathCoordinates, setPathCoordinates] = useState<CoordsInterface[]>([]);

    const [mustRedraw, setMustRedraw] = useState(false);
    const [canAddAnotherPoint, setCanAddAnotherPoint] = useState(true);
    const addPoint = (lat:number,lng:number, thelastone?:boolean) => {
        if(!canAddAnotherPoint){
            return;
        }

        setMustRedraw(true);
        let tmp = pathCoordinates;
        tmp.push({lat, lng});
        setPathCoordinates(tmp);

        if(tmp.length === 1) {
            setTerminationMarquer(tmp[0]);
        }

        if(!polyline){
            const tmppl = new google.maps.Polyline();
            tmppl.setPath(tmp);
            tmppl.setMap(map);
            setPolyline(tmppl);
        }else{
            polyline.setMap(null);
            polyline.setPath(tmp);
            polyline.setMap(map);
            setPolyline(polyline);
        }

        if(thelastone){
            setCanAddAnotherPoint(false);
        }
    }

    const removeLastPoint = () =>{
        setCanAddAnotherPoint(true);
        if(!polyline){
            const tmppl = new google.maps.Polyline();
            tmppl.setPath(pathCoordinates.slice(0,-1));
            tmppl.setMap(map);
            setPolyline(tmppl);
        }else{
            polyline.setMap(null);
            polyline.setPath(pathCoordinates.slice(0,-1));
            polyline.setMap(map);
            setPolyline(polyline);
        }
        if(pathCoordinates.length === 1) {
            setTerminationMarquer(null);
        }
        setPathCoordinates(pathCoordinates.slice(0,-1));
    }

    const selectBtnAction = () => {
        if(canAddAnotherPoint){
            showError();
        }else{
            confirmSelection()
        }
    }

    const confirmSelection = () => {
        props.onClose(pathCoordinates)
    }

    const showError = () => {
        setError('ERROR');
    }

    return (
        <React.Fragment>
            <Dialog
                open={error.length > 0}
                onClose={() =>{setError('')}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{zIndex: 10000}}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Erreur"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Veuillez compléter la sélection de la région.
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog
                fullScreen
                open={true}
                onClose={() => {}}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {props.onClose()}}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Sélectionner le secteur
                        </Typography>
                        <Button autoFocus color="inherit" onClick={() => {selectBtnAction()}}>
                            Sélectionner
                        </Button>
                    </Toolbar>
                </AppBar>
                <Grid container>
                    <Grid item md={9} sx={{minHeight: '93vh',backgroundColor:'GREEN', flexGrow: 3}}>
                        {
                            isLoaded &&
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={15}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                onClick={(ev) => {addPoint(ev.latLng?.lat() || 0, ev.latLng?.lng() || 0)}}
                            >
                                {
                                    terminationMarquer &&
                                    <Marker position={terminationMarquer} onClick={(ev) => {if(pathCoordinates.length > 2) addPoint(ev.latLng?.lat() || 0, ev.latLng?.lng() || 0, true)}} />
                                }
                            </GoogleMap>
                        }
                    </Grid>
                    <Grid item md={3} sx={{p:3}}>
                        <Typography variant={'subtitle1'}>Dessinez une région sur la carte</Typography>
                        {
                            pathCoordinates.length > 0 &&
                            <React.Fragment>
                                <Button onClick={() => {removeLastPoint()}} fullWidth={true} variant={'contained'} size={'small'} sx={{mt:3}}>Annuler le dernier point</Button>
                            </React.Fragment>
                        }
                    </Grid>
                </Grid>
            </Dialog>
        </React.Fragment>
    )
}
