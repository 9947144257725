const GSM_CHAR_SET = "@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà";

function isGSMEncoding(str: string): boolean {
    return Array.from(str).every(char => GSM_CHAR_SET.includes(char));
}
export function segmentsCounter(sms:string):number {
    const GSM_SINGLE_LIMIT = 160;
    const GSM_MULTI_LIMIT = 153;
    const UCS2_SINGLE_LIMIT = 70;
    const UCS2_MULTI_LIMIT = 67;

    const isGSM = isGSMEncoding(sms);

    if (isGSM) {
        if (sms.length <= GSM_SINGLE_LIMIT) {
            return 1;
        } else {
            return Math.ceil(sms.length / GSM_MULTI_LIMIT);
        }
    } else {
        if (sms.length <= UCS2_SINGLE_LIMIT) {
            return 1;
        } else {
            return Math.ceil(sms.length / UCS2_MULTI_LIMIT);
        }
    }
}
